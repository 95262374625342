// VerifyEmailOtp.js

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthLayout from '../components/layout/AuthLayout';
import { verifyOtp } from '../apis/apis'; 
import { useSnackbar } from '../../context/SnackbarContext';

const VerifyEmailOtp = () => {
    const history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [emailOtp, setEmailOtp] = useState(new Array(4).fill(""));
    const [isLoading, setIsLoading] = useState(false);

    const handleVerification = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await verifyOtp(emailOtp.join(""));
            if (response.success) {
                history.push("/user/home");
            } else {
                showSnackbar("Email OTP verification failed. Please try again.");
            }
        } catch (error) {
            console.error("Error verifying email OTP:", error);
            showSnackbar("An error occurred while verifying email OTP.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (value, index) => {
        const updatedOtp = [...emailOtp];
        updatedOtp[index] = value;
        setEmailOtp(updatedOtp);
    };

    return (
        <AuthLayout title="Verify Email OTP">
            <form onSubmit={handleVerification}>
                <p>Enter the OTP sent to your email address.</p>
                <div className="d-flex otp-wrapper">
                    {emailOtp.map((item, index) => (
                        <input
                            className="form-control py-3 me-2"
                            type="text"
                            maxLength="1"
                            key={index}
                            value={item}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onFocus={(e) => e.target.select()}
                        />
                    ))}
                </div>
                <button type="submit" disabled={isLoading}>Verify</button>
            </form>
        </AuthLayout>
    );
};

export default VerifyEmailOtp;
