import React from "react";
import CardContainer from "../components/Opportunity/CardContainer";
import { STATUS_CLOSED_OPPORTUNITY, STATUS_OPEN_OPPORTUNITY, STATUS_UPCOMING_OPPORTUNITY } from "../../utils/constants/constants";

function Opportunites() {
  return (
    <div className="my-4">
      <CardContainer title="Open Opportunity" opportunityType={STATUS_OPEN_OPPORTUNITY} />
      <CardContainer title="Upcoming Opportunity" opportunityType={STATUS_UPCOMING_OPPORTUNITY} />
      <CardContainer title="Closed Opportunity" opportunityType={STATUS_CLOSED_OPPORTUNITY} />
    </div>
  );
}

export default Opportunites;
