import React, { useEffect, useState } from 'react';
import AuthLayout from '../components/layout/AuthLayout';
import { getUserDetails } from '../apis/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip } from '@mui/material';

function SuccessfullPage() {

    const history = useHistory()

    const [isLoading, setIsLoading] = useState()
    const [ckycStatus, setCkycStatus] = useState(0);
    const [mcaStatus, setMcaStatus] = useState(0);

    const submit = async () => {
        setIsLoading(true)
        const userDetails = await getUserDetails()
        setIsLoading(false)
        if (userDetails.status) {
            const { mcaStatus, ckycStatus } = userDetails.data;

            if (ckycStatus && mcaStatus !== 0) {
                history.replace("/user/home");
            } else if (ckycStatus) {
                history.replace("/user/investmet-method");
            } else {
                history.replace("/user/kyc");
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
          const userDetails = await getUserDetails();
         
    
          if (userDetails.status) {
            const {mcaStatus, ckycStatus,countryCode } = userDetails.data;
            localStorage.setItem("CountryCode",countryCode);
            setCkycStatus(ckycStatus);
            setMcaStatus(mcaStatus);
    
            if (ckycStatus && mcaStatus !== 0) {
              history.replace("/user/home");
            }
          }
        };
        fetchData();
      }, []);

    const skipForNow = () => {
        history.replace("/user/home");
    };

    return (
        <AuthLayout title="Successfully Registered">
            <p className='fs-5'>Continue with {ckycStatus ? 'MCA E-Sign' : 'CKYC verification'}</p>
            <div className='d-flex mt-3'>
                <button type="submit" disabled={isLoading} onClick={submit} className="btn btn-primary px-3 py-2">Continue</button>
                {ckycStatus ?
                <button type="button" className="btn btn-light px-3 ms-3 py-2"
                    onClick={() => {
                        history.push("/user/home")
                    }
                    }>
                    Skip For Now
                </button> : null
            }
            </div>

            <div className="mt-3">
                <Tooltip
                    placement="bottom"
                    title={
                        <p> SCIF, or Salil Chakrabarty Innovation Fund, is a SEBI Registered Category 
                            1 Venture Capital Fund - Angel Fund bearing License No IN/AIF1/22-23/1102. 
                            KYC Verification is mandatory for investing in Regulated Financial Products in India 
                            under regulation of SEBI (Securities and Exchange Board of India).</p>
                    }
                    enterTouchDelay={0}
                >
                    <div>
                        <a className='text-decoration-none text-primary'>Why is this needed?</a>
                    </div>
                </Tooltip>
            </div>

        </AuthLayout>
    );
}

export default SuccessfullPage;