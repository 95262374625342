import React from "react";
import { useHistory } from "react-router-dom";

function CongratulationsSIP() {
  const history = useHistory();

  return (
    <div className="art-board py-4">
        <div className="container card-flat p-5">
            <img src={"/logo2.svg"} alt={"logo"} />

            <div className="container mt-5">
                <h3 className="title-md text-center mb-4">Congratulations!<br/>Your payment is successful!</h3>

                <div className="card-raised mx-auto w-75 p-5 mb-5">
                    <h3 className="card-title-md text-center">Our Representative will<br />contact you soon</h3>
                </div>
            </div>

            <div className="d-flex justify-content-center">
              <button className="color-btn mx-auto" onClick={()=>history.replace("/user/home/")}>Go to Portfolio Page</button>
            </div>
        </div>
    </div>
  );
}

export default CongratulationsSIP;