import React from 'react'

import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area,
    ResponsiveContainer,
} from "recharts";
import { toCompactPrice, toCompactPricePrefix } from '../../utils/StringUtil';

const CustomReChart = ({ chartData }) => {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            const activeItem = payload[0].payload

            return (
                <div className="bg-white p-3 rounded text-muted shadow" style={{ width: '250px' }}>
                    <p className='fs-6 mb-3 fw-bold'>{activeItem.month}</p>
                    <div className="row justify-content-between mb-2">
                        <div className='d-flex align-items-center w-auto'>
                            <div style={{ background: payload[0].color }} className='indicator d-block'></div>
                            <p className='fs-6 w-auto mb-0 ps-2'>Portfolio Value</p>
                        </div>
                        <p className='fs-6 mb-0 w-auto'>{toCompactPricePrefix(activeItem.portfolioValue)}</p>
                    </div>
                    <div className="row justify-content-between mb-0">
                        <div className='d-flex align-items-center w-auto'>
                            <div style={{ background: payload[1].color }} className='indicator d-block'></div>
                            <p className='fs-6 w-auto mb-0 ps-2'>Deployed</p>
                        </div>
                        <p className='fs-6 mb-1 w-auto'>{toCompactPricePrefix(activeItem.deployed)}</p>
                    </div>
                    <div className="row justify-content-between mb-2">
                        <div className='d-flex align-items-center w-auto'>
                            <div style={{ background: payload[1].color }} className='indicator d-block'></div>
                            <p className='fs-6 w-auto mb-0 ps-2'>Committed</p>
                        </div>
                        <p className='fs-6 mb-1 w-auto'>{toCompactPricePrefix(activeItem.committed)}</p>
                    </div>
                    <div className="row justify-content-between border-top pt-2">
                        <div className='d-flex align-items-center w-auto'>
                            <div style={{ background: payload[1].color }} className='indicator d-block'></div>
                            <p className='fs-6 w-auto mb-0 ps-2'>Invested</p>
                        </div>
                        <p className='fs-6 mb-1 w-auto'>{toCompactPricePrefix(activeItem.committed + activeItem.deployed)}</p>
                    </div>
                </div>
            );
        }

        return null;
    };

    const getLegendName = (key) => {
        switch (key) {
            case 'cd':
                return 'Invested'

            case 'portfolioValue':
                return 'Portfolio Value'
            default:
                return key
        }
    }

    const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div className='d-flex justify-content-end'>
                {
                    payload.map((entry, index) => (
                        <div className='d-flex align-items-center' key={`item-${index}`}>
                            <div className='indicator ms-4' style={{ background: entry.color }}></div>
                            <p className='mb-0 ms-2'>
                                {
                                    getLegendName(entry.value)
                                }
                            </p>
                        </div>
                    ))
                }
            </div>
        );
    }

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <ComposedChart
                    margin={{ top: 10, bottom: 10 }}
                    data={chartData}
                >
                    <CartesianGrid strokeDasharray='30 25' vertical={false} stroke="#dee2e6" />
                    <XAxis
                        dataKey="month"
                        scale="point"
                    />
                    <YAxis
                        color='#dee2e6'
                        tickFormatter={(value) => {
                            return toCompactPrice(value)
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        verticalAlign='top'
                        height={60}
                        content={renderLegend}
                    />
                    <Area legendType='square' type="step" fill="#CDCBFF" dataKey="portfolioValue" stroke="#CDCBFF" />
                    <Line legendType='plainline' dot={false} type="step" dataKey="cd" strokeWidth="3" stroke="#3a25f5" />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default CustomReChart