import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CompanyPdpModal = ({ open = true, title = '', description, onClose }) => {

    return (
        <Dialog
            fullScreen
            open={open}
        >
            <DialogTitle className='border-bottom' id="customized-dialog-title">
                <div className="row justify-content-between" >
                    <p className="fs-3 fw-bold mb-0 w-auto">
                        {title}
                    </p>
                    <div className='w-auto pointer' onClick={e => onClose(false)}>
                        <HighlightOffIcon className='text-dark' />
                    </div>
                </div>

            </DialogTitle>
            <DialogContent>
                <div className='mt-4' dangerouslySetInnerHTML={{ __html: description }} />
            </DialogContent>
        </Dialog>
    )
}

export default CompanyPdpModal