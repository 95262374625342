import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { toCompactPricePrefix } from '../../utils/StringUtil';

const CustomPieChart = ({ investmentByRounds }) => {

    useEffect(() => {
        setData(investmentByRounds.map(inv => {
            return {
                name: inv.invRound,
                value: inv.totCommitment
            }
        }))
    }, [investmentByRounds])


    const [data, setData] = useState([])

    const COLORS = ['#FFD700', '#FFA07A', '#FFC0CB', '#98FB98', '#B0E0E6', '#F5DEB3', '#FFA500', '#DDA0DD', '#00CED1', '#E6E6FA'];

    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {

            const item = payload[0].payload

            return (
                <div className="bg-white p-3 rounded text-muted shadow" style={{ width: 'auto' }}>
                    <div className="row justify-content-between">
                        <div className='d-flex align-items-center w-auto'>
                            <div style={{ background: item.fill }} className='indicator d-block'></div>
                            <p className='fs-6 w-auto mb-0 ps-2'>{item.name}</p>
                        </div>
                        <p className='fs-6 mb-0 w-auto'>{toCompactPricePrefix(item.value)}</p>
                    </div>
                </div>
            );
        }

        return null;
    };

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="#000"  textAnchor='start' className='fs-small-regular' dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}% `}
            </text>
        );
    };

    return (
        <>
            <div className="col-5 ps-0 mt-3">
                {
                    investmentByRounds.map((inv, index) => (
                        <div className="d-flex justify-content-between mt-2 ms-1" key={`item-${index}`}>
                            <div className='d-flex align-items-center' >
                                <div className='indicator' style={{ background: COLORS[index % COLORS.length] }}></div>
                                <p className='mb-0 ms-2 pe-3'>
                                    {inv.invRound}
                                </p>
                            </div>
                            <p className='mb-0'>
                                {toCompactPricePrefix(inv.totCommitment)}
                            </p>
                        </div>
                    ))
                }
            </div>
            <div className="col-7 px-0">
                <div style={{ width: '100%', height: 200 }}>
                    <ResponsiveContainer >
                        <PieChart>
                            <Pie
                                cx="50%"
                                cy="50%"
                                data={data}
                                innerRadius={40}
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="value"
                                label={renderCustomizedLabel}
                                labelLine={null}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    )
}

export default CustomPieChart