import { Checkbox, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Close, Search } from '@mui/icons-material';
import { apiCompanyUpdates, getDataDictionaryValues } from '../apis/apis';
import CompanyUpdateModal from '../components/CompanyUpdateModal';

const AllActivity = () => {
    const [open, setOpen] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [companyUpdateList, setCompanyUpdateList] = useState({});
    const [cuRid, setCuRid] = useState(null);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const handleOpen = (id) => {
        setOpen(true);
        setCuRid(id);
    }
    const handleClose = () => {
        setOpen(false);
        setCuRid(null);
    }

    const formatDate = (inputDate) => {
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = parseInt(dateParts[1], 10);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const monthName = monthNames[month - 1];
        return `${monthName} ${year}`;
    }

    const handleTagCheckbox = (index) => {
        const updatedList = tagsList.map(item =>
            item.ddIndex === index ? { ...item, isChecked: !item.isChecked } : item
        );
        setTagsList(updatedList);
    }

    const handleCompanyUpdateList = (data) => {
        const groupedData = {};

        data.forEach(item => {
            const date = new Date(item.publishDate);
            const monthYear = `${date.getFullYear()}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}`;

            if (!groupedData[monthYear]) {
                groupedData[monthYear] = [];
            }
            groupedData[monthYear].push(item);
        });

        setCompanyUpdateList(groupedData);
    }

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const tags = data.filter((item) =>
                    item.ddType === 11
                )
                setTagsList(
                    tags.map((item) => ({ ...item, isChecked: false }))
                );
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getCompanyUpdateList = (params = {}) => {
        const selTags = tagsList
            .filter(item => item.isChecked === true)
            .map(item => item.ddIndex)
            .join(',');
        params.pageNumber = null;
        params.title = searchTerm.trim();
        params.tags = selTags;
        params.recLimit = 15;
        setIsLoading(true);
        apiCompanyUpdates(params)
            .then((data) => {
                setIsLoading(false);
                handleCompanyUpdateList(data.data.companyUpdatesList);
                // if(page === 1){
                //   setTotalRecords(data.records);
                // }
            }).catch((e) => {
                setIsLoading(false);
                // enqueueSnackbar(e,SNACKBAR_ERROR)
            })
    }

    useEffect(() => {
        if (searchTerm.length < 3) {
            if (searchTerm.trim() === "") {
                getCompanyUpdateList();
            } else {
                return
            }
        } else {
            getCompanyUpdateList();
        }
        setPage(1);
    }, [searchTerm]);

    useEffect(() => {
        setPage(1);
        getCompanyUpdateList();
    }, [tagsList]);

    useEffect(() => {
        getDataDictionary();
    }, []);

    return (
        <div className="d-flex gap-5 my-4">
            <CompanyUpdateModal
                open={open}
                closeAction={handleClose}
                companyUpdateRid={cuRid}
            />

            <div className="d-flex gap-3 flex-column">
                <div className="d-flex align-items-center p-3 bg-white rounded-pill form-control w-auto" >
                    <Search fontSize="small" color="secondary" />
                    <input
                        type="text"
                        placeholder="Companies"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="fs-med-regular w-100 px-2"
                        style={{ border: 'none', outline: 'none' }}
                    />
                    <Close fontSize="small" color="secondary" className="custom-link" onClick={() => setSearchTerm('')} />
                </div>

                <div>
                    {tagsList.map((data, key) => {
                        return (
                            <div className="d-flex align-items-center" key={key}>
                                <Checkbox checked={data.isChecked} onChange={() => handleTagCheckbox(data.ddIndex)} />
                                <div className="d-flex flexgrow-1">
                                    <p className="fs-med-regular p-0 m-0">{data.ddValue}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="d-flex gap-3 flex-column flex-grow-1">
                {Object.keys(companyUpdateList).map((yearMonth) => {
                    return (
                        <div className='mb-3'>
                            <p className="fs-med-regular p-0 m-0 text-muted mb-2">{formatDate(yearMonth).toUpperCase()}</p>
                            {companyUpdateList[yearMonth]?.map((data, key) => {
                                return (
                                    <div key={key} className="card pointer p-3 mb-2" onClick={() => handleOpen(data.cuRid)}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column flex-grow-1">
                                                <h5 className="fs-6 mb-1">{data.title}</h5>
                                                <p className="fs-normal-medium text-muted p-0 m-0">{data.authorName}</p>
                                            </div>
                                            <div >
                                                <img
                                                    src={
                                                        data.logoUrl ?
                                                            process.env.REACT_APP_BASE_URL + "/" + data.logoUrl :
                                                            process.env.PUBLIC_URL + "/img/placeholder.png"
                                                    }
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
                                                    }}
                                                    alt="company-banner"
                                                    className="thumbnail-img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {isLoading && <CircularProgress className='mx-auto mt-3' />}
            </div>
        </div>
    );
}

export default AllActivity;