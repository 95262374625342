import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { BASE_URL } from '../../../utils/constants/constants'
import Footer from '../Footer'
import { getUserAuth } from '../../../utils/LocalStorageUtils'
import NavBar from '../NavBar'

const AuthLayout = ({ title, children }) => {

    const history = useHistory()
    let auth = getUserAuth();

    return (
        <div className='row mx-0 h-100vh auth-wrapper'>
            <div className="col-md">
                <img src={"/logo2.svg"} className='p-4' alt="logo" onClick={() => history.push("/user/home")} />
            </div>
            <div className="col-md-5 col-12 px-0 border-start border-end d-flex flex-column" style={{ minHeight: '100vh' }}>
                <div className="d-flex justify-content-center flex-column h-100 pt-5">
                    <h2 className='display-6 mb-4 px-5 title'>{title}</h2>
                    <div className='px-5'>
                        {children}
                    </div>
                </div>
                <div className='p-5'>
                    <Footer />
                </div>
            </div>
            <div className="col-md">
                {
                    auth
                    ? <NavBar showOnlyMenu />
                    : ''
                }
            </div>
        </div>
    )
}

export default AuthLayout