import React from 'react';
import ShimmerOpportunityCard from './ShimmerOpportunityCard';

function ShimmerOpportunityContainer() {
  return (
    <>
      <div className="row">
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
      </div>
      <div className="row">
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
      </div>
      <div className="row">
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
        <div className='col-md-6 col-12 mb-4'>
          <ShimmerOpportunityCard />
        </div>
      </div>
      </>

  );
}

export default ShimmerOpportunityContainer;