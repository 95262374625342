import React, { useEffect, useState } from 'react';
import AuthLayout from '../components/layout/AuthLayout';
import { apiNotifyAdmin, apiSaveBasicDetail, getUserDetails } from '../apis/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from '../../context/SnackbarContext';

const CkycNotAvailable = () => {
    let history = useHistory();
    const [investor, setInvestor] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          [name]: value,
        }));
      };

    const fetchUserData = () => {
        setIsLoading(true);
        getUserDetails()
            .then((res) => {
                setIsLoading(false);
                setInvestor(res.data);

                if(res.data.ckycStatus){
                  history.replace('/user/home');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                showSnackbar(error);
            });
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    const goBack = () => {
        history.replace('/user/kyc');
    }

      const notifyAdmin = () => {
        apiNotifyAdmin({
          contextType : 2
        })
        .then((res) => {
          showSnackbar('Success');
          setTimeout(() => {
            history.replace("/user/contact-team");
          }, 2000);
        })
        .catch((err) => {
          showSnackbar(err);
        });
      }

  return (
    <AuthLayout title="No CKYC records found">
          <div className='d-flex'>
          <button type="button" className="btn btn-primary px-3 py-2" onClick={() => notifyAdmin()}>Help me complete my KYC</button>
                    <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={() => goBack()}>Go Back</button>
          </div>
        {/* <form onSubmit={submit}>
                <p className='fs-normal-medium text-primary mb-4'>Please enter your name exactly as it appears on your PAN card.</p>
            
                <div className="form-floating mb-3">
                    <input
                        className='form-control'
                        placeholder="Full Name"
                        onChange={handleInputChange}
                        name='fullName'
                        value={investor?.fullName}
                        id="fullName"
                    />
                    <label htmlFor="fullName">First Name</label>
                </div>
                <div className='d-flex mt-3'>
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">Save</button>
                    <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={() => goBack()}>Go Back</button>
                </div>
            </form> */}
    </AuthLayout>
  );
}

export default CkycNotAvailable;