import React from 'react'
import { useState } from 'react';
import { apiEmailLoginGetOtp, apiEmailLoginInit, apiGetOTP } from '../apis/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setItem, HAS_MULTIPLE_ACCOUNTS, MULTIPLE_ACCOUNT_LIST } from '../../utils/LocalStorageUtils';
import AuthLayout from '../components/layout/AuthLayout';
import { useSnackbar } from '../../context/SnackbarContext';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Dialog, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function LoginPage() {

    const history = useHistory()
    const { showSnackbar } = useSnackbar()

    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [rawPhone, setRawPhone] = useState('')
    const [dialCode, setDialCode] = useState('')

    const [loginTab, setLoginTab] = useState(0);
    const [email, setEmail] = useState('');

    const [openAccountsDialog, setOpenAccountsDialog] = useState(false);
    const [accountsList, setAccountsList] = useState([]);

    const onPhoneNumberChange = (value, data, event, formattedValue) => {
        setRawPhone(value.slice(data.dialCode.length))
        setDialCode(data.dialCode)
        setPhoneNumber(value)
    }

    useEffect(() => {
        setPhoneNumber(localStorage.getItem("mobileNumber") ?? '')
        setRawPhone(localStorage.getItem("rawPhone") ?? '')
        setDialCode(localStorage.getItem("dialCode") ?? '')
    }, [])

    const onSubmit = (e) => {

        //TODO : validate mobile number - Wilson
        e.preventDefault()
        setIsLoading(true)

        // TODO: use constants for local storage keys - Wilson
        localStorage.setItem("mobileNumber", phoneNumber);
        localStorage.setItem("rawPhone", rawPhone);
        localStorage.setItem("dialCode", dialCode);
        localStorage.setItem("email", email);
      

        if(loginTab === 0){
            apiGetOTP({ mobileNumber: rawPhone, countryCode: `+${dialCode}`, login: true })
                .then((data) => {
                    // setUserAuth(true);
                    history.push({pathname: '/otp', state: {otpSentTo: data.otpSentTo}});
                })
                .catch((err) => {
                    showSnackbar(err)
                })
                .finally(() => setIsLoading(false));
        } else if(loginTab === 1){
            apiEmailLoginInit({ email: email })
                .then((res) => {
                    if(res.hasMultipleAccount){
                        setAccountsList(res.accounts);
                        setItem(HAS_MULTIPLE_ACCOUNTS,true);
                        setItem(MULTIPLE_ACCOUNT_LIST,JSON.stringify(res.accounts));
                        setOpenAccountsDialog(true);
                        
                    } else {
                        // setUserAuth(1);
                        history.push({pathname: '/otp', state: {otpSentTo: res.otpSentTo}});
                    }
                })
                .catch((err) => {
                    showSnackbar(err)
                })
                .finally(() => setIsLoading(false));
        }
    };

    const getSelectedAccountOtp = (userRid) => {
        apiEmailLoginGetOtp({ userRid: userRid })
            .then((res) => {
                // setUserAuth(true);
                history.push({pathname: '/otp', state: {otpSentTo: res.otpSentTo}});
            })
            .catch((err) => {
                showSnackbar(err)
            })
            .finally(() => {
                setAccountsList([]);
                setOpenAccountsDialog(false);
            });
    }

    return (
        <AuthLayout title="Login">
            <Dialog open={openAccountsDialog}>
                <div className="d-flex flex-column p-3" style={{minWidth: '260px'}}>
                    <h5 className="fs-large-semibold text-primary mb-3">Choose Your Login</h5>

                    {
                        accountsList?.map((data, key) => (
                            <div key={key}>
                                {key > 0 ? <Divider /> : ''}
                                <div className="d-flex gap-3 align-items-center py-2" style={{cursor: 'pointer'}} onClick={() => getSelectedAccountOtp(data.userRid)}>
                                    <AccountCircleIcon sx={{fontSize: '40px'}} />
                                    <span className="fs-med-regular">{data?.mobile}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Dialog>

            <form onSubmit={onSubmit}>
                <div className="row g-0 p-0 align-items-center p-1 border rounded mb-3">
                    <div 
                        onClick={() => setLoginTab(0)}
                        className={`col-6 d-flex justify-content-center p-2 rounded ${loginTab === 0 ? 'bg-light-purple' : ''}`}
                        style={{cursor: 'pointer'}}>
                        <span className="fs-med-medium">Mobile</span>
                    </div>
                    <div
                        onClick={() => setLoginTab(1)}
                        className={`col-6 d-flex justify-content-center p-2 rounded ${loginTab === 1 ? 'bg-light-purple' : ''}`}
                        style={{cursor: 'pointer'}}>
                        <span className="fs-med-medium">Email</span>
                    </div>
                </div>

                {
                    loginTab === 0
                    ? <div className="form-floating mb-3">
                        <PhoneInput
                            specialLabel=''
                            className="form-control custom-phone-input"
                            id="mobileNumber"
                            country={'in'}
                            autoFormat={false}
                            placeholder="Mobile"
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                        />
                        <label htmlFor="mobileNumber">Mobile</label>
                    </div>
                    : loginTab === 1
                    ? <div className="form-floating mb-3">
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name='email'
                            type="email" className="form-control"
                            id="email" placeholder=" "
                        />
                        <label htmlFor="email">Email ID</label>
                    </div>
                    : ''
                }
                
                {/* <a className='text-decoration-none' href="#forgot-password">Forgot password?</a> */}
                <div className='d-flex mt-3'>
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">Sign in</button>
                    {/* <button type="button" className="btn btn-light px-3 ms-3 py-2">Email me a sign in link </button> */}
                </div>
                <p className='mt-4'>New to Sprint VC? <a className='text-decoration-none' href="/register">Create an account</a></p>
                <p className='fs-normal-medium text-primary mt-4'>OTP is currently supported only for Indian mobile numbers. However, OTP is also sent on registered email address</p>
            </form>
        </AuthLayout>
    );
}
