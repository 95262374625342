import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getWallet } from "../apis/apis";
import { formattedoCurrencyNumbering } from "../../utils/userUtilities";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import { CONTACT_TEAM_MESSAGE } from "../../utils/constants/constants";
import CardLayout from "../components/layout/CardLayout";
import { useButtonContext } from "../../context/ButtonContext";

function WalletTopup() {
    const { setButtonText } = useButtonContext()
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [amount, setAmount] = useState('');
    const [walletAmount, setWalletAmount] = useState(0.00);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClick = () => {
        setButtonText('DONE')
    }

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const getWalletData = async () => {
        const walletData = await getWallet();

        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }

    const handleProceed = () => {
        if (amount > 0) {
            history.replace(`/user/payment?amount=${amount}&tranType=${process.env.REACT_APP_TYPE_WALLET_TOPUP}`);
        } else {
            setErrorMessage('Invalid Amount');
        }
        // handleDrawDown();
        // history.push("/user/nach-e-mandates/");
    };

    const contactTeam = () => {
        setOpenSnackbar(true);
    }

    useEffect(() => {
        getWalletData();
    }, []);

    return (
        <CardLayout>
            <div className="container mt-5">
                <h3 className="fs-4 text-center mb-4 text-dark">WALLET TOP-UP</h3>

                <div className="col-12 col-md-9 border rounded mx-auto p-4 p-md-5 mb-4">
                    <h3 className="fs-2 text-secondary text-center mb-3">Please enter the amount</h3>
                    <p className="text-center py-3 fs-4 px-4 text-secondary">
                        <span className="fw-bold">Current Balance:</span> {formattedoCurrencyNumbering(walletAmount)}/-
                    </p>
                    <div className="col-12 col-md-6 mx-auto">

                        <div className="form-floating mb-3">
                            <input
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                                type="text" className="form-control"
                                placeholder="Amount"
                                id="amount"
                            />
                            <label htmlFor="amount">Amount</label>
                        </div>
                        <p className="text-danger">{errorMessage}</p>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        className="btn btn-primary px-4 py-2 mb-5"
                        onClick={() => {
                            handleProceed();  
                            handleClick();  
                        }}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading..." : "PROCEED"}
                    </button>
                </div>
            </div>

            <CustomSnackbar
                open={openSnackbar}
                severity="error"
                duration={4000}
                text={CONTACT_TEAM_MESSAGE}
                handleClose={handleSnackbarClose}
            />
        </CardLayout>
    );
}

export default WalletTopup;