import React, {useEffect} from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useHistory, useLocation } from 'react-router-dom';

const ConsentSuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const stateParams = new URLSearchParams(location.state);
  const schemeTitle = stateParams.get('schemeTitle');

  useEffect(() => {
    // localStorage.clear();
    // if(!schemeTitle){
    //   history.replace("/");
    // }
  }, []);

  return (
    <>
      <div className="container py-4">
        <div className="d-flex gap-3 flex-column align-items-center justify-content-center w-50 mx-auto">
          <img src={process.env.PUBLIC_URL + "/IMG-consent-header.png"} alt={"logo"} className="w-100" />

          <CheckCircleRoundedIcon sx={{ fontSize: 60 }} color="success" />
          <h3 className="fs-title-regular text-center">
            Thank you for consenting to the
            <span className="fs-title-semibold ms-2">{schemeTitle}</span>
          </h3>
        </div>
      </div>
    </>
  );
}

export default ConsentSuccess;