
const USER_AUTH = "user_auth";
const USER_AUTH_TOKEN = 'user_auth_token'
const USER_REFRESH_TOKEN = 'user_refresh_token';
const USER_TRANSACTION_TOKEN = 'user_transaction_token';
export const HAS_MULTIPLE_ACCOUNTS = 'has_multiple_accounts'
export const MULTIPLE_ACCOUNT_LIST = 'multiple_account_list'
export const LSK_MCA_STATUS = '787sgsjwAfgfgastw';
const DASHBOARD_TAB = 'dashboard_tab';
export const COMPANY_PDP = 'kjdcjqoiqeoi_company_pdp';

export const FLAG_REDIRECT_FROM_DOCUMENTS='56567sdfssdfsf_ferfefdffg' 
export const FLAG_REDIRECT_FROM_PREVIEW = 'jhghfgdfsd7685645fdfgdg'
 
export const LS_INV_CODE="INV_CODE"


export const setUserAuth = (data) => {
  localStorage.setItem(USER_AUTH, data);
};
export const getUserAuth = () => {
  return localStorage.getItem(USER_AUTH);
};

export const setUserAuthToken = (data) => {
  localStorage.setItem(USER_AUTH_TOKEN, data);
};
export const getUserAuthToken = () => {
  return localStorage.getItem(USER_AUTH_TOKEN);
};

export const setUserRefreshToken = (data) => {
  localStorage.setItem(USER_REFRESH_TOKEN, data);
};
export const getUserRefreshToken = () => {
  return localStorage.getItem(USER_REFRESH_TOKEN);
};

export const setUserTransactionToken = (data) => {
  localStorage.setItem(USER_TRANSACTION_TOKEN, data);
};
export const getUserTransactionToken = () => {
  return localStorage.getItem(USER_TRANSACTION_TOKEN);
};

export const setItem = (key, data) => {
  localStorage.setItem(key, data)
}

export const getItem = (key) => {
  return localStorage.getItem(key)
}

export const getDashboardTab = () => {
  return localStorage.getItem(DASHBOARD_TAB)
}

export const setDashboardTab = (data) => {
  localStorage.setItem(DASHBOARD_TAB, data)
}



