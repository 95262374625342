import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {
    apiTransactionDetails,
    getWallet,
    apiGetCommitmentDetails,
    apiGetConvenienceFee,
    apiCommitmentTransaction,
    apiGetSchemeEmailerConvenienceFee,
    getWalletSchemeEmailer,
    apiGetRegisteredCommitmentDetails,
    apiCommitmentTransactionSchemeEmailer,
    apiGetRegisteredTransactionDetails
} from "../apis/apis";
import { formattedCurrency } from "../../utils/userUtilities";
import CardLayout from "../components/layout/CardLayout";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import { IconButton, FormControl, FormGroup, FormControlLabel, Checkbox, Box, Dialog } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useSnackbar } from "../../context/SnackbarContext";
import { Close } from "@mui/icons-material";


function PaymentGatewayPage() {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tranType = searchParams.get('tranType');
    const ioRid = searchParams.get('ioRid');
    const isEmailInvestment = searchParams?.get('isEmailInvestment');
    const token = searchParams.get('token')
    const [walletAmount, setWalletAmount] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [toastStatus, setToastStatus] = useState("error");
    const [paymentType, setPaymentType] = useState('');
    const [commitmentDetail, setCommitmentDetail] = useState(null)
    const [walletOption, setWalletOption] = useState(false);
    const [payOffline, setPayOffline] = useState(false);
    const [convenienceFee, setConvenienceFee] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false)
    const [includeConvenienceFee, setIncludeConvenienceFee] = useState(true);
    const [PaymentStatus, setPaymentStatus] = useState(false);
    let history = useHistory()
    const { showSnackbar } = useSnackbar();

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleWalletOption = (e) => {
        setWalletOption(e.target.checked);
        setPayOffline(false);
    };

    // const handlePayOffline = (e) => {
    //     setPayOffline(e.target.checked);
    //     setWalletOption(false);
    // };

    const saveOffline = () => {
        setIncludeConvenienceFee(false)
        setPayOffline(true);
    }

    const handleClose = () => {
        setPayOffline(false)
        setIncludeConvenienceFee(true)
    };

    const confirmPayOffline = () => {
        setIsLoading(true);
        if (isEmailInvestment) {
            apiCommitmentTransactionSchemeEmailer(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline }, { token: token })
                .then((res) => {
                    if (res.status === "SUCCESS") {
                        history.replace("/user/Home");
                    } else if (res.status === "INITIATED") {
                        makePayment(res.data);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        } else {
            apiCommitmentTransaction(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline })
                .then((res) => {
                    if (res.status === "SUCCESS") {
                        history.replace("/user/Home");
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        }

    }


    const getConvenienceFee = () => {
        apiGetConvenienceFee()
            .then((data) => {
                setConvenienceFee(data.convenienceFee);
            })
    }

    const getSchemeEmailConvenienceFee = () => {
        apiGetSchemeEmailerConvenienceFee()
            .then((data) => {
                setConvenienceFee(data.convenienceFee);
            })
    }

    const getSchemeEmailWalletData = async () => {

        const walletData = await getWalletSchemeEmailer({ "token": token });

        // setIsLoading(false)
        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }

    const getCommitmentDetails = () => {
        apiGetCommitmentDetails(ioRid)
            .then((res) => {
                setCommitmentDetail(res);
                localStorage.setItem("email", res.email);
            })
            .catch((err) => {
                showSnackbar(err)
            });
    }

    const getSchemeEmailCommitmentDetails = () => {
        apiGetRegisteredCommitmentDetails(ioRid, token)
            .then((res) => {
                setCommitmentDetail(res);
            })
            .catch(e => showSnackbar(e))
    }

    const getWalletData = async () => {
        const walletData = await getWallet();

        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }

    const makePayment = (data) => {
        var flow_config = {
            merchantId: data.merchantId,
            bdOrderId: data.bdorderId,
            authToken: data.token,
            childWindow: true,
            returnUrl: '',
            crossButtonHandling: 'Y',
            retryCount: 0
        };

        var responseHandler = function (txn) {
            if (txn.txnResponse) {
                setIsLoading(true);
                getTransactionDetails(data.orderId);
            } else {
                setErrorMessage("Transaction Not Completed");
                setIsLoading(false);
                setToastStatus("error");
                setOpenSnackbar(true);
            }
        };

        var config = {
            responseHandler: responseHandler,
            flowConfig: flow_config,
            flowType: "payments"
        };
        window.loadBillDeskSdk(config);
    }

    const handleCreateCommitTransaction = (ioRid) => {
        setIsLoading(true);
        if (isEmailInvestment) {
            apiCommitmentTransactionSchemeEmailer(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline }, { token: token })
                .then((res) => {
                    setIsLoading(false)
                    console.log(res)
                    if (res.status === "SUCCESS") {
                        setShowSuccess(true);
                    }
                    else {
                        makePayment(res.data);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        } else {
            apiCommitmentTransaction(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline })
                .then((res) => {
                    setIsLoading(false)
                    if (res.status === "SUCCESS") {
                        setShowSuccess(true);
                    }
                    else {
                        makePayment(res.data);
                    }

                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        }

    }

    const getTransactionDetails = (orderid) => {
        setIsLoading(true);
        if (isEmailInvestment) {
            apiGetRegisteredTransactionDetails(orderid, token)
                .then((res) => {
                    setIsLoading(false);
                    setTimeout(() => {
                        history.replace({
                            pathname: "/user/payment-status",
                            state: { transactionResponse: res, token: token, ioRid: ioRid, isEmailInvestment: isEmailInvestment }
                        });
                    }, 1000);
                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        } else {
            apiTransactionDetails(orderid)
                .then((res) => {
                    setIsLoading(false);
                    setTimeout(() => {
                        history.replace({
                            pathname: "/user/payment-status",
                            state: { transactionResponse: res, ioRid: ioRid, isEmailInvestment: isEmailInvestment }
                        });
                    }, 1000);
                }).catch((err) => {
                    setIsLoading(false);
                    setErrorMessage(err.toString());
                    setToastStatus("error");
                    setOpenSnackbar(true);
                });
        }

    }
    const handleProceed = () => {
        handleCreateCommitTransaction(ioRid);
    }

    const handleCloseDialog = () => {
        setPayOffline(false);
        setWalletOption(false);
    };

    useEffect(() => {
        if (tranType === process.env.REACT_APP_TYPE_COMMITMENT) {
            setPaymentType('Commitment Payment');
        }
        if (!isEmailInvestment) {
            getCommitmentDetails(ioRid);
            getConvenienceFee();
            getWalletData();

        } else {
            getSchemeEmailWalletData();
            getSchemeEmailConvenienceFee();
            getSchemeEmailCommitmentDetails();
        }

    }, [])

    const PaymentSummary = () => {
        return (

            <div className="d-flex justify-content-center flex-column mx-auto col-8">
                <table className="table table-bordered w-auto">
                    <tbody>
                        <tr >
                            <td><p className="fs-med-semibold p-0 m-0 ">Commitment</p></td>
                            <td><p className="fs-med-semibold text-end p-0 m-0"><b> {formattedCurrency(commitmentDetail.commitment)}</b> </p></td>
                        </tr>
                        <tr>
                            <td><p className="fs-med-semibold p-0 m-0 ">Upfront Fees</p></td>
                            <td><p className="fs-med-semibold text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.upfrontAmount)}</b> </p></td>
                        </tr>
                        <tr>
                            <td><p className="fs-med-semibold p-0 m-0 ">Statutory Fees</p></td>
                            <td><p className="fs-med-semibold text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.gst)}</b> </p></td>
                        </tr>


                        {commitmentDetail.dematCharges ?
                            <tr>
                                <td><p className="fs-med-semibold p-0 m-0 ">Demat Charges</p></td>
                                <td><p className="fs-med-semibold text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.dematCharges)}</b> </p></td>
                            </tr> : null
                        }
                        {includeConvenienceFee && (
                            (!payOffline && !walletOption) || (walletOption && walletAmount < commitmentDetail.totalAmount) ? (
                                <tr>
                                    <td><p className="fs-med-semibold p-0 m-0">Convenience Fees</p></td>
                                    <td><p className="fs-med-semibold text-end p-0 m-0"><b>+ {formattedCurrency(convenienceFee)}</b></p></td>
                                </tr>
                            ) : null
                        )}

                        <tr>
                            <td><p className="fs-med-semibold p-0 m-0">Total Amount</p></td>
                            {payOffline || (payOffline && walletOption) ?
                                <td><p className="fs-med-semibold text-end p-0 m-0"><b>
                                    {formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td> :

                                <td><p className="fs-med-semibold text-end p-0 m-0"><b> {!payOffline && !walletOption || (walletOption && walletAmount < commitmentDetail.totalAmount) ?
                                    formattedCurrency(parseFloat(commitmentDetail.totalAmount) + parseFloat(convenienceFee)) :
                                    formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td>
                            }

                        </tr>
                        {walletAmount > 0 && walletOption ? (
                            <tr>
                                <td><p className="fs-med-semibold p-0 m-0">Wallet Balance Applied</p></td>
                                <td><p className="fs-med-semibold text-end p-0 m-0">
                                    {walletAmount <= commitmentDetail.totalAmount ? (
                                        <b>- {formattedCurrency(walletAmount)}</b>
                                    ) : (

                                        <b>- {formattedCurrency(commitmentDetail.totalAmount)}</b>
                                    )}
                                </p></td>
                            </tr>
                        ) : null}
                        {payOffline ?
                            <tr>
                                <td><p className="fs-med-semibold p-0 m-0">Amount Payable</p></td>
                                {payOffline && walletOption ?
                                    <td><p className="fs-med-semibold text-end p-0 m-0"><b>
                                        {formattedCurrency(parseFloat(commitmentDetail.totalAmount) - walletAmount)}</b></p></td> :
                                    <td><p className="fs-med-semibold text-end p-0 m-0"><b>
                                        {formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td>

                                    // <td><p className="fs-med-semibold text-end p-0 m-0"><b> {!payOffline && !walletOption || (walletOption && walletAmount < commitmentDetail.totalAmount) ?
                                    //     formattedCurrency(parseFloat(commitmentDetail.totalAmount) + parseFloat(convenienceFee)) :
                                    //     formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td>
                                }

                            </tr> : null
                        }
                    </tbody>
                </table>
            </div>

        );
    }

    if (!commitmentDetail) {
        return (<div className="loading-page">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>)
    }

    if (showSuccess)
        return (
            <>
                <div className="d-flex flex-column min-vh-100">
                    <NavBar />
                    <div className="container mt-3 px-5 mx-auto flex-grow-1 overflow-auto">
                        <div className="my-5 py-5">
                            <p className="fs-title-lg-semibold fw-bold text-center text-capitalize my-2">{commitmentDetail.oppTitle}</p>
                            <p className="fs-title-semibold text-center my-2">Thank you for your Investment</p>
                            <div className="my-4">
                                <p className="fs-large-semibold text-bold text-center">
                                    You will receive an email once the funds are deployed
                                </p>
                            </div>
                            <div className="d-flex justify-content-center my-4 py-2">
                                <button onClick={() => history.replace("/user/home")} className="btn btn-lg btn-primary w-auto">
                                    Explore Other Opportunities
                                </button>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

            </>
        )
    return (
        <><CardLayout>
            <div className="container mx-2 my-2">
                <p className="fs-title-lg-semibold fw-bold text-center ">{commitmentDetail.oppTitle}</p>
                {walletAmount > 0 ?
                    <div className="border rounded mx-auto w-75 p-2 ">
                        <Box className='my-2'>
                            <div className='d-flex flex-row gap-2 justify-content-center'>
                                <p className='fs-large-semibold'>Wallet Balance</p>
                                <p className='fs-large-semibold'>: {formattedCurrency(walletAmount)}</p>
                            </div>

                            <FormControl component="fieldset" fullWidth>
                                <Box display="flex" justifyContent="center">
                                    <FormGroup>
                                        <b>
                                            <FormControlLabel
                                                control={<Checkbox checked={walletOption} onChange={handleWalletOption} />}
                                                label="Use Wallet Balance"
                                                labelPlacement="end" />
                                        </b>
                                    </FormGroup>
                                </Box>
                            </FormControl>
                        </Box>
                    </div> : null}
                {/* Payment Summary Table */}
                <div className="border rounded mx-auto w-75 my-2">

                    <h3 className="fs-large-semibold text-dark text-center p-0 m-0 mt-2">
                        Payment Summary
                    </h3>
                    {PaymentSummary()}
                </div>
                <div className="border rounded mx-auto w-75 p-4 mb-4">
                    <h3 className="fs-2 text-secondary text-center p-0 m-0">
                        Amount Payable:
                        {!walletOption && !payOffline ? (
                            <b>{formattedCurrency(
                                parseFloat(commitmentDetail.totalAmount) +
                                parseFloat(convenienceFee)
                            )}</b>
                        ) : payOffline ? (
                            <b>{formattedCurrency(
                                parseFloat(commitmentDetail.totalAmount)
                            )}</b>
                        ) : (
                            walletOption && walletAmount >= commitmentDetail.totalAmount ? (
                                <b>{formattedCurrency(
                                    parseFloat(0)
                                )}</b>
                            ) : (
                                <b>{formattedCurrency(
                                    (parseFloat(commitmentDetail.totalAmount) +
                                        parseFloat(convenienceFee)) -
                                    walletAmount
                                )}</b>
                            )
                        )}
                    </h3>
                </div>
            </div>

            <div className="d-flex justify-content-center gap-5">
                <button
                    className="btn btn-primary px-4 py-2 mb-5"
                    onClick={handleProceed}
                    disabled={isLoading}
                >
                    {(isLoading && !payOffline) ? "Loading..." : walletOption && walletAmount >= commitmentDetail.totalAmount ? "CONFIRM" : "PAY ONLINE"}
                </button>
                {walletOption && walletAmount >= commitmentDetail.totalAmount ? null :
                    <button
                        className="btn btn-primary px-4 py-2 mb-5"
                        onClick={saveOffline}
                        disabled={isLoading}
                    >
                        {isLoading && payOffline ? "Loading..." : "PAY OFFLINE"}
                    </button>}
            </div>


            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus}
                duration={4000}
                text={errorMessage}
                handleClose={handleSnackbarClose} />
        </CardLayout>
            <Dialog
                open={payOffline}
                onClose={!payOffline}
            >
                <div className="d-flex flex-column justify-content-end p-3]" style={{ minWidth: '590px' }}>

                    <div className="d-flex justify-content-between align-items-center  p-3">
                        <div>
                            <h5 className="fs-large-semibold text-primary">Pay Offline</h5>
                        </div>
                        <div>
                            <IconButton color="secondary" onClick={handleClose}>
                                <Close fontSize="medium" />
                            </IconButton>
                        </div>

                    </div>
                    <div >

                        <h4 className="fs-large-semibold text-primary text-center p-0 m-0">
                            Payment Summary
                        </h4>
                        {PaymentSummary()}
                    </div>
                    <h4 className="fs-large-semibold text-primary text-center p-0 m-0 my-2">
                        Bank Details
                    </h4>
                    <div className="d-flex flex-column justify-content-center  mx-4 px-4" >

                        <div className="d-flex justify-content-center p-0 m-0 ">
                            <div className="">
                                <table className="p-0 m-0 table-responsive ">
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">Name</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> SALIL CHAKRABARTY INNOVATION FUND</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">Bank</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> RBL BANK</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">A/c No</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> 409001821513</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">IFSC</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> RATN0000116</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">SWIFT</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> RATNINBB</p></td>
                                    </tr>
                                    <tr>
                                        <td><p className="p-0 m-0 px-4">TYPE</p></td>
                                        <td><p className="p-0 m-0 text-end">:</p></td>
                                        <td><p className="p-0 m-0"> Current Account</p></td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <button
                            className="btn btn-sm btn-primary px-4 py-2 mb-5"
                            onClick={confirmPayOffline}
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading..." : "CONFIRM"}
                        </button>

                    </div>
                </div>
            </Dialog></>

    );
}
export default PaymentGatewayPage;