import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';

const FileView = ({ url, title, fileName, showDownload }) => {

    const isPDF = url?.toLowerCase().endsWith('.pdf');
    const isImage = /\.(jpg|jpeg|png|gif)$/.test(url?.toLowerCase());


    const handleDownload = async () => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = isPDF ? fileName || 'file' + ".pdf" : fileName
            link.click();
            window.URL.revokeObjectURL(link.href); // Clean up
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div className='d-flex justify-content-between align-items-center mb-3'>
           <div>
           <span className="fs-med-semibold fw-bold text-center mb-4">File Name : </span>
                <span className="fs-normal-semibold text-center mb-4">{fileName}
                </span>
           </div>

                {showDownload&&isPDF && (
                    <button  onClick={handleDownload} className="btn btn-primary  gap-2 w-auto px-4">
                        Download 
                        <DownloadIcon fontSize="small" />
                    </button>
                )}


            </div>
            {isPDF ? (
                <>
                    <iframe
                        src={url}
                        style={{ width: '100%', height: '600px', border: 'none' }}
                        title={title}
                    ></iframe>
                    <div>


                    </div>
                </>
            ) : isImage ? (
                <img
                    src={url}
                    alt={title}
                    style={{ maxWidth: '100%', maxHeight: '600px' }}
                />
            ) : (
                <p>Unsupported file type</p>
            )}
        </div>
    );
};

export default FileView;
