import React, { useEffect, useState } from "react";
import { getDrawDown, getOpportunityDetails, getUserDetails } from "../apis/apis";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { formattedoCurrencyNumbering } from "../../utils/userUtilities";
import CardLayout from "../components/layout/CardLayout";

function LumpsumIO() {
    const [isLoading, setIsLoading] = useState(false);
    const {oppRid} = useParams();
    const history = useHistory();
    const [amount, setAmount] = useState('');
    const [minAmount, setMinAmount] = useState(0);

    const [isAmountValid, setIsAmountValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const recAmountList = [
        {"amt": minAmount},
        {"amt": (minAmount*2)},
        {"amt": (minAmount*3)},
    ]

    const handleAmountChange = (value) => {
        setAmount(value);
        setIsAmountValid(validateAmount(value));
    };

    const validateAmount = (value) => {
        const rgx = /^[0-9]*\.?[0-9]*$/;
        if(minAmount > value){
            setErrorMessage("Amount below min value");
            return false;
        }
        if(!value.match(rgx)){
            setErrorMessage("Invalid Amount");
            return false;
        }
        return true;
    };

    const fetchuserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { invPref, invPrefRequest } = userDetails.data;
        }
    };

    const handleDrawDown = () => {
        setIsLoading(true);
        getDrawDown({
            "oppRid": oppRid, "amount": amount, "redirectUrl": window.location.origin + "/user/drawdown-status", "tranType": process.env.REACT_APP_TYPE_LUMPSUM_PG
        }).then((data) => {
            if(data.status){
                window.open(data.data.url, "_self");
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }

    const handleProceed = () => {
        if(isAmountValid){
            handleDrawDown();
        }
    }

    const fetchOpportunityDetails = async () => {
        const opportunityDetails = await getOpportunityDetails({oppRid: oppRid});

        if (opportunityDetails.status) {
            const { minAmount } = opportunityDetails.data;
            setMinAmount(minAmount);
            handleAmountChange(minAmount);
        }
    }

    useEffect(() => {
        fetchuserData();
        fetchOpportunityDetails();
    }, []);

    return (
        <CardLayout>
            <div className="container mt-5">
                <h3 className="title-md text-center mb-4">Lumpsum Investor Onboarding</h3>

                <div className="col-12 col-md-9 card-raised mx-auto p-4 p-md-5 mb-5">
                    <h3 className="card-title-md text-center mb-4">How much do you wish to<br />invest in project name ?</h3>

                    <div className="form-outline col-12 col-md-6 mx-auto">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Amount"
                            onChange={(e) => handleAmountChange(e.target.value)}
                            value={amount}
                            id="amount"
                        />
                        {!isAmountValid ? <p className="text-danger">{errorMessage}</p> : ''}
                        <h5 className="card-text mt-3 mb-5">Min INR {formattedoCurrencyNumbering(minAmount)}/-</h5>
                    </div>
                    <h5 className="card-title-sm text-center mb-4">RECOMMENDED AMOUNT</h5>
                    <div className="row row-cols-auto g-4 justify-content-center mb-3">
                        {
                            recAmountList.map((data,key)=>{
                                return(
                                    <div className="col">
                                        <span className={amount === data.amt ? 'label-rounded-filled' : 'label-rounded-outline' } onClick={()=>handleAmountChange(data.amt)}>{formattedoCurrencyNumbering(data.amt)}/-</span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>

                <div className="d-flex justify-content-center mb-5">
                    <button className="color-btn" onClick={handleProceed} disabled={isLoading}>
                        {isLoading ? "Loading..." : "PROCEED"}
                    </button>
                </div>
            </div>
        </CardLayout>
    );
}

export default LumpsumIO;