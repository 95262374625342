import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiSaveBankInfo, getDataDictionaryValues, getUserDetails } from '../../apis/apis';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DD_TYPE_BANK_TYPE, DD_TYPE_INVESTOR_TYPE } from '../../../utils/constants/constants';

const BankDetails = () => {
    let history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [bankNameList, setBankNameList] = useState([]);
    const [investorTypeList, setInvestorTypeList] = useState([]);
    const [showOtherInput, setShowOtherInput] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        bankName: "",
        bankIndex: null,
        bankAddress: "",
        accountNo: "",
        ifscSwift: "",
        otherBankName: "",
    });

    // Handle selection of bank and toggle 'Other' input
    // Handle selection of bank and toggle 'Other' input
    const handle = (e) => {
        const { id, value } = e.target;

        // If the bank selection is being updated
        if (id === 'bankIndex') {
            if (value === 'other') {
                setShowOtherInput(true);
                setData({ ...data, bankIndex: 0});
            } else {
                setShowOtherInput(false);
                setData({ ...data, bankIndex: value, otherBankName: null }); // Reset otherBankName when not 'other'
            }
        } else {
            // For other inputs like bankAddress, accountNo, etc.
            setData({ ...data, [id]: value });
        }
    };



    // Handle the input for the 'Other Bank Name'
    const handleOtherInputChange = (event) => {
        setData({ ...data, otherBankName: event.target.value, bankName: event.target.value });
    };

    // Handle form submission
    const submit = (e) => {
        e.preventDefault();

        // Check if bank name is selected and if 'Other' is handled
        if (data?.bankIndex === null || data?.bankIndex === '---' ||
            (data?.bankIndex === 'other' && data?.otherBankName === "")) {
            showSnackbar("Please select a valid bank name.");
            return;
        }

        setIsLoading(true);

        apiSaveBankInfo(data)
            .then(() => {
                history.replace("/user/demat-details");
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    // Fetch user details
    const fetchUserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { address, bankName, bankIndex, bankAddress, accountNo, ifscSwift, otherBankname, investorType } = userDetails.data;

            setData({
                // address: address,
                bankName: bankName,
                bankIndex: bankIndex,
                bankAddress: bankAddress,
                accountNo: accountNo,
                ifscSwift: ifscSwift,
                otherBankname: otherBankname,
                // investorType: investorType,
                otherBankName: otherBankname,
            });
        }
    };

    // Fetch bank and investor type data
    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) => item.ddType === DD_TYPE_INVESTOR_TYPE);
                const bankTypes = data.filter((item) => item.ddType === DD_TYPE_BANK_TYPE);
                setInvestorTypeList(invTypes);
                setBankNameList(bankTypes);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        fetchUserData();
        getDataDictionary();
    }, []);

    return (
        <AuthLayout title="Bank Details">
            <form onSubmit={submit}>
                {/* Bank Name Selection */}
                <div className="form-floating mb-3">
                    <select
                        className="form-select"
                        id="bankIndex"
                        name="bankIndex"
                        onChange={handle}
                        value={data.bankIndex || null}
                        aria-label="Bank Name Selection"
                    >
                        <option value={null}>---</option>
                        {
                            bankNameList?.map(item => <option value={item.ddIndex}>{item.ddValue}</option>)
                        }

                        <option value="other">Other</option>
                    </select>
                    <label htmlFor="bankIndex">Bank Name</label>

                    {showOtherInput && (
                        <div className="form-floating mb-3 mt-3">
                            <input
                                className="form-control"
                                placeholder="Other Bank Name"
                                onChange={handleOtherInputChange}
                                name="otherBankName"
                                value={data.otherBankName || ""}
                                id="otherBankName"
                                type="text"
                            />
                            <label htmlFor="otherBankName">Enter Other Bank Name</label>
                        </div>
                    )}
                </div>

                {/* Bank Address */}
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="Bank Address"
                        onChange={handle}
                        name="bankAddress"
                        value={data.bankAddress}
                        id="bankAddress"
                        type="text"
                    />
                    <label htmlFor="bankAddress">Bank Address</label>
                </div>

                {/* Account Number */}
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="Bank Account No"
                        onChange={handle}
                        name="accountNo"
                        value={data.accountNo}
                        id="accountNo"
                        type="text"
                    />
                    <label htmlFor="accountNo">Bank Account No</label>
                </div>

                {/* IFSC / SWIFT */}
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="IFSC / SWIFT"
                        onChange={handle}
                        name="ifscSwift"
                        value={data.ifscSwift}
                        id="ifscSwift"
                        type="text"
                    />
                    <label htmlFor="ifscSwift">Bank IFSC / SWIFT</label>
                </div>

                {/* Submit Button */}
                <div className="d-flex mt-3">
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
                        Save & Continue
                    </button>
                </div>
            </form>
        </AuthLayout>
    );
};

export default BankDetails;
