import React, { useEffect, useState } from 'react';
import AuthLayout from '../components/layout/AuthLayout';
import { apiSaveBasicDetail, getUserDetails } from '../apis/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from '../../context/SnackbarContext';

const UpdateFullName = () => {
    let history = useHistory();
    const [investor, setInvestor] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          [name]: value,
        }));
      };

    const fetchUserData = () => {
        setIsLoading(true);
        getUserDetails()
            .then((res) => {
                setIsLoading(false);
                setInvestor(res.data);

                if(res.data.ckycStatus){
                  history.replace('/user/home');
                }
            })
            .catch((error) => {
                setIsLoading(false);
                showSnackbar(error);
            });
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    const goBack = () => {
        history.replace('/user/kyc');
    }

    const submit = (e) => {
        e.preventDefault()

        setIsLoading(true);
        apiSaveBasicDetail({
          fullName: investor?.fullName,
          dobDoi: investor?.dobDoi,
          investorType: investor?.investorType,
          address: investor?.address
        })
          .then((res) => {
            showSnackbar('Data Saved');
            goBack();
          })
          .catch((err) => {
            showSnackbar(err);
          })
          .finally(() => setIsLoading(false));
      }

  return (
    <AuthLayout title="Update Name">
        <form onSubmit={submit}>
                <p className='fs-normal-medium text-primary mb-4'>Please enter your name exactly as it appears on your PAN card.</p>
            
                <div className="form-floating mb-3">
                    <input
                        className='form-control'
                        placeholder="Full Name"
                        onChange={handleInputChange}
                        name='fullName'
                        value={investor?.fullName}
                        id="fullName"
                    />
                    <label htmlFor="fullName">Name</label>
                </div>
                <div className='d-flex mt-3'>
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">Save</button>
                    <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={() => goBack()}>Go Back</button>
                </div>
            </form>
    </AuthLayout>
  );
}

export default UpdateFullName;