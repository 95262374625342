import { Slider } from "@mui/material";
import { styled } from "@mui/system";

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#470061",
  "& .MuiSlider-rail": {
    backgroundColor: "blue",
    height: 12,
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 12,
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "purple",
    border: "2px solid transparent",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "0 0 0 8px rgba(0, 0, 0, 0.3)",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1,
    fontSize: 25,
    padding: 10,
    borderRadius: 10,
    color: "purple",
    backgroundColor: "#ECECFD",
  },
  "& .MuiSlider-markLabel": {
    color: "#9090ED",
    fontSize: 18,
  },
}));
