import { Snackbar } from "@mui/material"
import { createContext, useCallback, useContext, useMemo, useState } from "react"


const SnackbarContext = createContext(undefined)

export function useSnackbar() {
    const context = useContext(SnackbarContext)
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider')
    }
    return context
}

export function SnackbarProvider({ children }) {
    const [message, setMessage] = useState('')

    const showSnackbar = useCallback((message) => {
        setMessage(message)
    }, [])

    const hideSnackbar = useCallback(() => {
        setMessage('')
    }, [])

    // Memoize the context value to avoid unnecessary re-renders in child components
    const contextValue = useMemo(() => ({
        showSnackbar,
        hideSnackbar
    }), [showSnackbar, hideSnackbar]);

    return (
        <SnackbarContext.Provider value={contextValue}>
            {/* TODO : add actions and close button */}
            <Snackbar
                open={!!message}
                autoHideDuration={6000}
                onClose={hideSnackbar}
                message={message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
            {children}
        </SnackbarContext.Provider>
    );
}




