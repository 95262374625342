import axios from "axios";

export const httpPost = ({
  path,
  queryParams = null,
  body = null,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${path}`,
        body,
        {
          params: queryParams,
        }
      );

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const httpGet = ({
  path,
  queryParams = null,
  body = null,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}${path}`,
        {
          params: queryParams
        }
      );

      resolve(response);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const httpGetBlob = ({
  path,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        path,
        {
          responseType: "blob",
          headers: {
            Accept: "*/*",
          },
        }
      );
      resolve(response);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const httpPut = ({ path, body = null }) => {
  return new Promise((resolve, reject) => {
      axios.put(
          `${process.env.REACT_APP_BASE_URL}${path}`,
          body
      )
          .then((res) => {
              return resolve(res)
          })
          .catch((e) => {
              return reject(e)
          })
  })
}

export const httpFileUpload = ({ path, file = null }) => {

  return new Promise((resolve, reject) => {
      axios.post(
          `${process.env.REACT_APP_BASE_URL}${path}`,
          file
      )
      .then((res) => {
           return resolve(res)
      })
      .catch((e) => {
          return reject(e)
      })

  })
}
