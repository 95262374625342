import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

function CardLayout({ children }) {
  const history = useHistory();

  return (
    <div className="art-board py-md-4 d-flex flex-column h-100">
      <div className="container card-flat flex-grow-1 p-4 p-md-4 overflow-auto">
        <div className="d-flex gap-3 align-items-center">
          <IconButton size="large" onClick={() => history.replace("/user/home")}>
            <ArrowBackIcon />
          </IconButton>
          <img src={"/logo2.svg"} onClick={() => history.push("/user/home")} alt={"logo"} />
        </div>

        {children}
      </div>
    </div>
  );
}

export default CardLayout;
