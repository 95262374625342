export const CreateDigio = (documentId, email, GWT_id) => {
  return new Promise((resolve, reject) => {
    const digoOptions = {
      "is_redirection_approach": false,
            "is_iframe": true,
            "environment": "sandbox",
    };

    digoOptions.callback = (_digio) => {
      if (_digio.error_code === "CANCELLED") {
        resolve({ status: false, description: "Flow cancelled by user" });
        return;
      }
      if (_digio.txn_id) {
        resolve(_digio);
      } else {
        reject(_digio);
      }
    };
    const digio = new window.Digio(digoOptions);
    digio.init();
    digio.submit(documentId, email, GWT_id);
  });
};
