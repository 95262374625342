import React, { useEffect, useState } from "react";
import LayoutKYC from "../components/layout/LayoutKYC";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getDrawDownDetails, getUserDetails } from "../apis/apis";
import { useLocation } from 'react-router-dom';
import CustomSnackbar from "../components/Toast/CustomSnackbar";

function DrawdownStatus() {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const envelopId = searchParams.get('envelopId');

    const oppRid = searchParams.get('oppRid');
    const amount = searchParams.get('amount');
    const tranType = searchParams.get('tranType');

    const [errorData, setErrorData] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleDrawDownDetails = async () => {
        const drawnDownDetails = await getDrawDownDetails({
            "envelopId": envelopId
        });

        if (drawnDownDetails.status) {
            const { status } = drawnDownDetails.data;
            if(status ===  1){
                if(tranType === process.env.REACT_APP_TYPE_WALLET_OPPORTUNITY){
                    history.push(`/user/congratulations-sip?oppRid=${oppRid}&amount=${amount}&tranType=${tranType}`);
                } else {
                    history.push(`/user/payment?oppRid=${oppRid}&amount=${amount}&tranType=${tranType}`);
                }
            } else {
                setErrorData('DrawDown not signed');
                setOpenSnackbar(true);
                setTimeout(() => {
                    history.push("/user/company-pdp/"+oppRid);
                }, 2000);
            }
        } else {
            setErrorData(drawnDownDetails.description);
            setOpenSnackbar(true);
            setTimeout(() => {
                history.push("/user/company-pdp/"+oppRid);
            }, 2000);
        }
    }

    useEffect(() => {
        handleDrawDownDetails();
    }, []);

  return (
    <LayoutKYC>
        <div className="loading-page">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>

        <CustomSnackbar
            open={openSnackbar}
            severity="error"
            duration={2000}
            text={errorData}
            handleClose={handleSnackbarClose}
        />
    </LayoutKYC>
  );
}

export default DrawdownStatus;