import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { CustomSlider } from "../components/CustomSlider";
import {Divider } from "@mui/material";
import { formattedCurrency } from "../../utils/userUtilities";
import { sipPreference } from "../apis/apis";
import CustomSnackbar from "../components/Toast/CustomSnackbar";

function InvestSIPMethod() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorData, setErrorData] = useState("");
    const [toastStatus, setToastStatus] = useState("error");

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const [sipAmount, setSipAmount] = useState({
        noOfInstallments: 35,
        investmentAmount: 270000,
    });

    const handleSipAmountChange = (event, newValue) => {
        setSipAmount({
          ...sipAmount,
          investmentAmount: newValue,
        });
    };

    const [months, setMonths] = useState(35);
    const handleMonthChange = (event, newValue) => {
        setMonths(newValue);
    };

    const submit = () => {
        setIsLoading(true);
        const data =
        { noOfInstallments: months,
        investmentAmount: sipAmount.investmentAmount };
    
        sipPreference(data)
          .then((response) => {
            if (response.status) {
                setToastStatus("success");
                setErrorData(response.description);
                setOpenSnackbar(true);
                setTimeout(() => history.push("/user/nach-e-mandates/"), 2000);
            } else {
                setToastStatus("error");
                setErrorData(response.description);
                setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            setToastStatus("error");
            setErrorData(error);
            setOpenSnackbar(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

    return (
        <div className="art-board py-4">
            <div className="container card-flat p-5">
                <img src={"/logo2.svg"} alt={"logo"} />

                <div className="container mt-5">
                    <h3 className="title-md text-center mb-4">Monthly SIP Investment</h3>

                    <div className="card-raised mx-auto w-75 py-4 px-5 mb-5">
                        <p className="card-text text-center mt-4">Select Monthly Amount</p>
                        <CustomSlider
                            aria-label="custom thumb label"
                            step={5000}
                            min={50000}
                            max={1000000}
                            valueLabelDisplay="auto"
                            value={sipAmount.investmentAmount}
                            marks={[
                            { value: 50000, label: "INR 50,000" },
                            { value: 1000000, label: "INR 10,00,000" },
                            ]}
                            onChange={handleSipAmountChange}
                        />
                        <Divider
                            sx={{ bgcolor: "blue", width: "100%", margin: "20px 0" }}
                        />
                        <CustomSlider
                            aria-label="custom thumb label"
                            step={1}
                            min={12}
                            max={60}
                            valueLabelDisplay="auto"
                            value={months}
                            marks={[
                            { value: 12, label: "12 months" },
                            { value: 60, label: "60 months" },
                            ]}
                            onChange={handleMonthChange}
                        />
                        <div className="d-flex justify-content-center">
                            <h5
                                className="text-green mt-4" style={{borderBottom: "3px dashed"}}
                            >
                                Total INR:{" "}
                                {formattedCurrency(sipAmount.investmentAmount * months)}
                                /-
                            </h5>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <button className="color-btn mx-auto" onClick={submit} disabled={isLoading}>
                        {isLoading ? "Loading..." : "CONTINUE"}
                    </button>
                </div>
            </div>

            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus}
                duration={4000}
                text={errorData}
                handleClose={handleSnackbarClose}
            />
        </div>
    );
}

export default InvestSIPMethod;