import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getDrawDownDetails, getUserDetails } from "../apis/apis";
import { useLocation } from 'react-router-dom';
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import CardLayout from "../components/layout/CardLayout";
import NumericStepper from "../components/NumericStepper";
import { getUserAuth } from "../../utils/LocalStorageUtils";
import AuthLayout from "../components/layout/AuthLayout";

function McaSuccessfull(props) {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mcaEvent = searchParams.get('event');
    const envelopId = searchParams.get('envelopId');

    const [errorData, setErrorData] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const [mcaStatus, setMcaStatus] = useState(-1);

    const handleContinue = async () => {
        history.replace("/user/nomination", { redirectPath: "user/fatca" });
    };

    const fetchMcaData =  () => {
        var eventData = {};
        getDrawDownDetails({"envelopId": envelopId})
        .then((res)=>{
            if (res.status) {
            
                const { status } = res.data;
                eventData = {responseStatus:true, reponseMessage:"success", "mcaStatus": status };
               
    
                if (status === 1) {
                    if (getUserAuth()) {
                        setMcaStatus(status);
                    } else {
                        history.replace("/success-lp", { "alertTitle": 'MCA Successfully Signed' });
                    }
                } else {
                    // setErrorData('MCA not signed');
                    setErrorData("You need to complete the MCA signing to proceed further with the investments");
                    setOpenSnackbar(true);
                    setTimeout(() => {
                        history.replace("/user/agreement");
                    }, 2000);
                }
            } else {
                setErrorData(res.description);
                setOpenSnackbar(true);
                setTimeout(() => {
                    history.replace("/user/agreement");
                }, 2000);
    
                eventData = {responseStatus:false, reponseMessage:res.description, "mcaStatus": null };
               
                // if(mcaEvent === 'signing_complete'){
                //     setMcaStatus(1);
                // } else {
                //     setMcaStatus(0);
                // }
            }
            
        })
        .catch((err)=>{
            setErrorData(err);
            setOpenSnackbar(true);
            setTimeout(() => {
                history.replace("/user/agreement");
            }, 2000);

            eventData = {responseStatus:false, reponseMessage:err, "mcaStatus": null };
           
        })
        .finally(() => {
            var customEvent = new CustomEvent('myCustomEvent', { detail: eventData });
            window.dispatchEvent(customEvent);

        })
       
    };

    useEffect(() => {
        setTimeout(() => {
            fetchMcaData();
        }, 2000);
        function handleCustomEvent(event) {
            console.log('Custom event received:', event.detail.mcaStatus);
        }
    
        window.addEventListener('myCustomEvent', handleCustomEvent);
    
        return () => {
            window.removeEventListener('myCustomEvent', handleCustomEvent);
        };

    }, []);

    return (
        <AuthLayout title={mcaStatus === -1 ? '' : 'MCA Successfully Signed'}>
            {
                mcaStatus === -1
                    ? <div className="d-flex justify-content-center">
                        <div className="loading-spinner"></div>
                    </div>
                    : <>
                        <p className='fs-normal-medium text-primary mb-4'>Trustee and Investment  Manager signatures pending . You will be notified once the document is ready for download.</p>
                        <div className='d-flex mt-3'>
                            <button type="button" onClick={handleContinue} className="btn btn-primary px-3 py-2">Continue</button>
                        </div>
                    </>

            }
            <CustomSnackbar
                open={openSnackbar}
                severity="error"
                duration={2000}
                text={errorData}
                handleClose={handleSnackbarClose}
            />


        </AuthLayout>
        // <CardLayout>
        //     {
        //         mcaStatus === -1 ? <div className="loading-page">
        //         <div className="loading-spinner"></div>
        //         <p className="loading-text">Loading...</p>
        //         </div> : 
        //         <>
        //             <div className="container mt-5">
        //                 <NumericStepper />
        //                 <h3 className="title-md text-center mb-4">MCA Successfully Signed</h3>
        //                 <p className="card-text text-center mb-5">Trustee and Investment  Manager signatures pending . You will be notified once the document is ready for download.</p>
        //             </div>
        //             <div className="d-flex justify-content-center mb-5">
        //                 <button className="color-btn mx-auto" onClick={handleContinue}>CONTINUE</button>
        //             </div>
        //         </>
        //     }

        // </CardLayout>

    );
}

export default McaSuccessfull;
