import "../../../scss/custom.scss";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "../../../context/SnackbarContext";

const UserLayout = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#5f58ff', // Replace with the actual primary color
      },
      secondary: {
        main: '#6c757d', // Replace with the actual secondary color
      },
    },
    typography: {
      fontFamily: 'Poppins, sans-serif', // Replace with the actual body font
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </ThemeProvider>
  )
};

export default UserLayout;
