import React from 'react';
import { useHistory } from "react-router-dom";
import AuthLayout from '../components/layout/AuthLayout';

function ContactTeam() {
    const history = useHistory();

    return (
        <AuthLayout title="Processing!">
            <p className='fs-5'>Your request is under process. Our representative will contact you soon.</p>
            {/* <div className='d-flex mt-3'>
                <button type="submit" onClick={() => history.replace("/user/home/")} className="btn btn-primary px-3 py-2">
                    Go to Portfolio Page
                </button>
            </div> */}
        </AuthLayout>
    )
}

export default ContactTeam;