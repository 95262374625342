import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { getEsignURL, getNominationEsignURL } from "../../apis/apis";
import CardLayout from "../../components/layout/CardLayout";
import NumericStepper from "../../components/NumericStepper";
import { setDashboardTab,getUserAuth,getItem,FLAG_REDIRECT_FROM_DOCUMENTS } from "../../../utils/LocalStorageUtils";
import AuthLayout from "../../components/layout/AuthLayout";
import { isForeigner } from "../../../utils/StringUtil";


function NominationPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setURL] = useState("");
  const [errorData, setErrorData] = useState("");
  let history = useHistory();

  const handleProceed = () => {
    if(url !== ''){
      setIsLoading(true);
      localStorage.setItem("Nomination", true);
      window.open(url, "_self");
    }
  }
  const handleSkip = async () => {
   
    if(isForeigner()){
        history.replace("/user/fatca");
    }else{
      setDashboardTab(1);
      history.push("/user/home")
    }
};

  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("Nomination", false);
    getNominationEsignURL({
      "redirectUrl": window.location.origin + "/user/nomination-status"
    })
      .then(({ data, status, description }) => {
        if (status) {
          setTimeout(() => {
            setIsLoading(false);
            setURL(data.url);
          }, 2000);
        } else {
          setIsLoading(false);
          setErrorData(description);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrorData(err);
      });
  }, []);

  return (
    <AuthLayout title="Nomination">
      <p className='fs-normal-medium text-primary mb-2'>
      Nomination can be submitted now or anytime in the future. 
      Since your investments will be held in a DMAT account, 
      not having a nomination saved with us will not affect the nomination on your DMAT account.</p>
      {/* <p className='fs-normal-medium text-primary mb-4'>To get started , we need a little bit of information from you .</p> */}

      <p className='fs-normal-medium text-danger mb-4'>{
        errorData
      }
      </p>

      <div className='d-flex mt-3'>
        {isLoading ? <CircularProgress /> : 
        <><button type="submit" disabled={isLoading || errorData !== ''} className="btn btn-primary px-3 py-2" onClick={handleProceed}>Continue</button>
        {getUserAuth() && !getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
        <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={handleSkip}>Skip for now</button>:null}</>
        }
      
      </div>
    </AuthLayout>
  );
}

export default NominationPage;
