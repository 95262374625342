import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { getEsignURL, getFatcaEsignURL } from "../../apis/apis";
import CardLayout from "../../components/layout/CardLayout";
import NumericStepper from "../../components/NumericStepper";
import { getUserAuth, getItem, FLAG_REDIRECT_FROM_DOCUMENTS } from "../../../utils/LocalStorageUtils";
import AuthLayout from "../../components/layout/AuthLayout";


function FatcaPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setURL] = useState("");
  const [errorData, setErrorData] = useState("");
  let history = useHistory();

  const handleProceed = () => {
    if (url !== '') {
      setIsLoading(true);
      localStorage.setItem("Fatca", true);
      window.open(url, "_self");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("Fatca", false);
    getFatcaEsignURL({
      "redirectUrl": window.location.origin + "/user/fatca-status"
    })
      .then(({ data, status, description }) => {
        if (status) {
          setTimeout(() => {
            setIsLoading(false);
            setURL(data.url);
          }, 2000);
        } else {
          setIsLoading(false);
          setErrorData(description);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrorData(err);
      });
  }, []);

  return (
    <AuthLayout title="Foreign Account Tax Compliance Act(FATCA)">
      <p className='fs-normal-medium text-primary mb-2'>
      This is in connection to the Financial Account Tax Compliance Act (FATCA) and Common Reporting Standard (CRS) related compliance.
We would like to inform you of the importance of this and must ensure that we can continue providing you with our services effectively and securely.
As a part of various regulations including SEBI and Income Tax Regulations, we have to update the FATCA / CRS of each investor with the regulatory authorities every year.
Request you to provide all data asked in the form at the earliest as it is very critical for our internal documentation purposes as well.</p>
      {/* <p className='fs-normal-medium text-primary mb-4'>To get started , we need a little bit of information from you .</p> */}

      <p className='fs-normal-medium text-danger mb-4'>{
        errorData
      }
      </p>

      <div className='d-flex mt-3'>
        {isLoading ? <CircularProgress /> :
          <><button type="submit" disabled={isLoading || errorData !== ''} className="btn btn-primary px-3 py-2"
            onClick={handleProceed}
          >Continue</button>

          </>
        }
        {/* {parseInt(getUserAuth()) === 1 && !getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
            <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={() => history.push("/user/communication-details")}>Skip for now</button>
          : ""} */}

      </div>
    </AuthLayout>
  );
}

export default FatcaPage;
