import React, { useEffect, useState } from 'react'
import { apiGetCommitmentDetails, 
        apiSaveNonRegisteredCommitment, 
        apiSaveRegisteredCommitment, 
        getOpportunityDetails,  
        saveInvestment, 
        apiUpdateInvestorCommitment,
        apiGetRegisteredCommitmentDetails } from '../apis/apis';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toCompactPrice } from '../../utils/StringUtil';
import { useSnackbar } from '../../context/SnackbarContext';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { getUserAuth, LS_INV_CODE} from '../../utils/LocalStorageUtils';
import ReportIcon from '@mui/icons-material/Report';
import DrawDownComponent from '../components/DrawDownComponent';
import AlertLayout from "../../components/layout/AlertLayout";

const InvestNow = () => {

    const { oppRid } = useParams()
    let history = useHistory()
    const location = useLocation();
    const { showSnackbar } = useSnackbar()
    const searchParams = new URLSearchParams(location.search);
    const editIoRid = searchParams.get('ioRid') || 0;
    const [isLoading, setIsLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [investmentAmount, setinvestmentAmount] = useState(0.00)
    const [oppDetail, setOppDetail] = useState(null)
    const [investmentError, setInvestmentError] = useState(null)
    const [showDrawdown, setShowDrawdown] = useState(false)
    const [drawdownData, setDrawdownData] = useState(null)
    const { isEmailInvestment, minAmount, token, iid } = location.state || {};
    const [showContactTeam, setShowContactTeam] = useState(false);
    const [ioRid, setIoRid] = useState(0);
    const [showSuccesForNonRegistered, setShowSuccessForNonRegistered] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [alertLoader, setAlertLoader] = useState(false);
    const [commitmentDetail, setCommitmentDetail] = useState(null)

    useEffect(() => {
        if(editIoRid){
            apiGetCommitmentDetails(editIoRid)
            .then((res) => {
                setCommitmentDetail(res);
                setinvestmentAmount(res.commitment);
                localStorage.setItem("email", res.email);
            })
            .catch((err) => {
                console.log(err);
            });
            if (isEmailInvestment) {
                setOppDetail(location.state || {});
                // setinvestmentAmount(minAmount);
            } else {
                getOpportunityDetails({ oppRid: oppRid })
                    .then((res) => {
                        setOppDetail(res.data);
                        // setinvestmentAmount(res.data.minAmount)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }else{
            if (isEmailInvestment) {
                setOppDetail(location.state || {});
                setinvestmentAmount(minAmount);
            } else {
                getOpportunityDetails({ oppRid: oppRid })
                    .then((res) => {
                        setOppDetail(res.data);
                        setinvestmentAmount(res.data.minAmount)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }  
    }, [])

    const saveCommitment = () => {
       
        setIsLoading(true)

        const body = {
            commitment: investmentAmount,
            oppRid: oppRid,
            token: isEmailInvestment ? token : null,
            ioRid:oppDetail.ioRid
        }
        if (iid === 0 || iid === null) {
            setIsLoading(true)
            apiSaveNonRegisteredCommitment(body)
                .then((data => {
                    setIsLoading(false)
                    if (data) {
                        history.replace("/success-lp", { "alertTitle": data.message });
                    }
                }))
        } else if (isEmailInvestment) {
            setIsLoading(true)
            apiSaveRegisteredCommitment(body)
                .then(res => {
                    setIsLoading(false);
                    if (res) {
                        apiGetRegisteredCommitmentDetails(res.ioRid, token)
                            .then((res) => {
                                // setCommitmentDetail(res);
                                setOppDetail(res);
                            })
                            .catch(e => showSnackbar(e))
                        setShowDrawdown(true);
                        setIoRid(res.ioRid);
                        setDrawdownData(res);
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    setShowSuccessForNonRegistered(true);
                    setErrorMessage(e || 'An unknown error occurred.');
                })
        } else {
            saveInvestment(body)
                .then(data => {
                    setIsLoading(false)
                    apiGetCommitmentDetails(data.ioRid)
                        .then((res) => {
                            setOppDetail(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setShowDrawdown(true)
                    setIoRid(data.ioRid)
                    setDrawdownData(data)
                }).catch(e => {
                    showSnackbar(e)
                }).finally(() => {
                    setSaving(false)
                })
        }
    }

    const handleAlertDialog = () => {
        // setAlertLoader(true);
        const body = {
            commitment: parseFloat(investmentAmount),
            oppRid: oppRid,
            token: isEmailInvestment ? token : null,
            ioRid : editIoRid,
            cmRid : commitmentDetail.cmRid
        }

        apiUpdateInvestorCommitment(body)
        .then(data => {
            // setAlertLoader(false)
            apiGetCommitmentDetails(data.ioRid)
                .then((res) => {
                    setOppDetail(res);
                })
                .catch((e) => {
                     showSnackbar(e)
                });
            setShowDrawdown(true)
            setIoRid(data.ioRid)
            setDrawdownData(data)
        }).catch(e => {
            showSnackbar(e)
        }).finally(() => {
            setSaving(false)
            toggleAlertDialog();
        })
      }
    
      
      const toggleAlertDialog = () => {
        if (openAlertDialog) {
          setOpenAlertDialog(false);
        } else {
          setOpenAlertDialog(true);
        }
      };

    const onConfirm = () => {
    
        if (!+investmentAmount) {
            setInvestmentError("This feild cannot be empty")
            return
        }
        if (+investmentAmount < oppDetail.minAmount) {
            setInvestmentError(`Minimum Investment amount is ${toCompactPrice(oppDetail.minAmount)}`)
            return
        }
        if(editIoRid === null || editIoRid ===0){
            saveCommitment();
        }else{
            toggleAlertDialog();
        }
        
    }

    const onInputChange = (e) => {
        setinvestmentAmount(e.target.value)
        setInvestmentError(null)
    }

    if (showContactTeam)
        return (
            <>
                <div >
                    {isEmailInvestment ? '' : <NavBar />}
                    {(!isLoading && oppDetail) && <div className="container mt-5">
                        <p className="fs-title-lg-semibold fw-bold text-center mb-4">{oppDetail.oppTitle}</p>
                        <div className="row mb-4">
                            <div className="p-5 border border-success rounded d-flex gap-1 flex-column align-items-center">
                                <span className="fs-title-semibold">Your Request Is Under Process</span>
                                <span className="fs-med-regular">
                                    Our Representative will contact you soon
                                </span>
                            </div>
                        </div>
                    </div>
                    }
                    <Footer />
                </div>
            </>
        )

    if (showDrawdown) {
        const invCode = localStorage.getItem(LS_INV_CODE)

        return (
            <DrawDownComponent isEmailInvestment={isEmailInvestment} path={drawdownData?.drawdown.path} oppDetail={{ invCode: invCode, ...oppDetail }} token={token} ioRid={ioRid} />
        )
    }
    if (showSuccesForNonRegistered)

        return (
            <div className="container card-flat p-4 p-md-5">
                <img src={"/logo2.svg"} alt={"logo"} />

                <div className="loading-page">
                    {

                        isLoading ? <div className="loading-spinner"></div> : <ReportIcon sx={{ fontSize: 80 }} color="action" />
                    }
                    <p className="loading-text">
                        {
                            isLoading ? 'Loading...' : errorMessage
                        }
                    </p>
                </div>
            </div>
        )
    if (isLoading) {
        return (<div className="loading-page">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>)
    }

    return (
        <>
            <div >
                {isEmailInvestment ? '' : <NavBar />}
                {(!isLoading && oppDetail) && <div className="container mt-5">
                    <p className="fs-title-lg-semibold fw-bold text-center mb-5">{showDrawdown ? 'Drawdown - ' : ''}{oppDetail.oppTitle}</p>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="p-5 mb-5 border rounded d-flex flex-column align-items-center ">
                                <div className="col-3 px-0">
                                    <div className={`form-floating my-4`}>
                                        <input
                                            className={`form-control form-control-lg ${investmentError ? 'is-invalid' : ''}`}
                                            placeholder="Investment Amount"
                                            onChange={onInputChange}
                                            name="investmentAmount"
                                            value={investmentAmount}
                                            id="investmentAmount"
                                        />
                                        <label htmlFor="investmentAmount" className='pt-2 pb-2'>
                                            <span className="fs-large-semibold text-dark">Investment Amount</span>
                                        </label>

                                        <div id="investmentAmount" className="invalid-feedback">
                                            {investmentError}
                                        </div>
                                        {/* {walletAmount > 0?
                                        <Box className='mt-3' sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px' }}>
                                        <div className='d-flex flex-row gap-3'>
                                            <p className='fs-med-semibold'>Wallet Balance</p>
                                            <p className='fs-med-semibold'>: {formattedCurrency(walletAmount)}</p>
                                        </div>
                                        <FormControl component="fieldset" fullWidth>
                                            <FormGroup >
                                                <FormControlLabel
                                                    control={<Checkbox checked={walletOption} onChange={handleWalletOption} />}
                                                    label="Use Wallet Balance"
                                                    labelPlacement='end'
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>:null} */}
                                    </div>
                                    <div className="row justify-content-end">
                                        {isEmailInvestment && !getUserAuth() ? <button disabled={saving} onClick={() => history.push('/')} className="btn w-auto px-4 me-3">
                                            Cancel
                                        </button> : <button disabled={saving} onClick={() => history.push('/user/home')} className="btn w-auto px-4 me-3">
                                            Cancel
                                        </button>}

                                        <button disabled={saving} onClick={onConfirm} className="btn btn-primary w-auto px-4">
                                            {saving ? 'Loading' : 'Confirm'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Footer />
                <AlertLayout
                    open={openAlertDialog}
                    title="Are You Sure ?"
                    actionLoader={alertLoader}
                    closeAction={toggleAlertDialog}
                    positiveAction={
                        { label: 'Continue', onClick: handleAlertDialog }
                    }>
                    <p className="fs-large-medium p-0 m-0">Your old commitment will be deleted permanently. Do you want to continue?</p>
                </AlertLayout>
            </div>
        </>
    )
}

export default InvestNow