import React, {useEffect} from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useHistory, useLocation } from 'react-router-dom';

const SuccessLP = () => {
    const history = useHistory();
    const location = useLocation();
    const stateParams = new URLSearchParams(location.state);
    const alertTitle = stateParams.get('alertTitle');

    useEffect(() => {
        localStorage.clear();
        if(!alertTitle){
            history.replace("/");
        }
    }, []);

  return (
    <div className="art-board py-md-4">
        <div className="container card-flat p-4 p-md-5">
            <img src={"/logo2.svg"} alt={"logo"} />

            <div className="container mt-5">
                <div className="d-flex gap-3 align-items-center flex-column py-5">
                <CheckCircleRoundedIcon sx={{ fontSize: 80 }} color="success" />
                <h3 className="title-md">{alertTitle}</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SuccessLP;