import React from "react";
import { useHistory } from "react-router-dom";

const ErrorPage = () => {
  let history = useHistory();
  const goBack = () => {
    // window.history.back();
    history.push("/user/agreement");
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ height: "100vh" }}
    >
      <div>
        <h1>oops... Something went wrong !</h1>
        <button className="color-btn" onClick={goBack}>
          Go Back
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
