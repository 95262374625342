import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomSlider } from "../components/CustomSlider";
import { Radio, Divider } from "@mui/material";
import { formattedCurrency } from "../../utils/userUtilities";
import { getUserDetails, saveInvestmentPreference } from "../apis/apis";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import CardLayout from "../components/layout/CardLayout";
import NumericStepper from "../components/NumericStepper";
import { getUserAuth } from "../../utils/LocalStorageUtils";
import AuthLayout from "../components/layout/AuthLayout";

const InvestmentMethod = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [toastStatus, setToastStatus] = useState("error");

  const [lumpsumData, setLumpsum] = useState({
    investmentAmount: 50000000,
    investmentType: 1,
  });
  const [sipAmount, setSipAmount] = useState({
    noOfInstallments: 35,
    investmentAmount: 270000,
    investmentType: 2,
  });
  const [months, setMonths] = useState(35);
  const [investmentType, setInvestmentType] = useState(2);

  const history = useHistory();

  const handleLumpsum = (event, newValue) => {
    setLumpsum({
      ...lumpsumData,
      investmentAmount: newValue,
    });
  };

  const handleSipAmountChange = (event, newValue) => {
    setSipAmount({
      ...sipAmount,
      investmentAmount: newValue,
    });
  };

  const handleMonthChange = (event, newValue) => {
    setMonths(newValue);
  };

  const handleRadioChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setInvestmentType(selectedValue);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const submit = () => {
    setIsLoading(true);
    // const data =
    //   investmentType === 1
    //     ? lumpsumData
    //     : {
    //       noOfInstallments: months,
    //       investmentAmount: sipAmount.investmentAmount,
    //       investmentType: 2,
    //     };

    saveInvestmentPreference(lumpsumData) // replaced with data with lumpsum amount jira ticket - SPNVC-199
      .then((response) => {
        if (response.status) {
          setToastStatus("success");
          setErrorData(response.description);
          setOpenSnackbar(true);
          setTimeout(() => history.replace("/user/communication-details"), 2000);
        } else {
          setToastStatus("error");
          setErrorData(response.description);
          setOpenSnackbar(true);
        }
      })
      .catch((error) => {
        setToastStatus("error");
        setErrorData(error);
        setOpenSnackbar(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { mcaStatus, ckycStatus, invPref } = userDetails.data;

      if (ckycStatus && mcaStatus !== 0) {
        history.replace("/user/home");
      } else if (ckycStatus) {
        if (invPref === 0 && mcaStatus === 0) {
          history.replace("/user/investmet-method");
        } else {
          history.replace("/user/agreement");
        }
      } else {
        history.replace("/user/kyc");
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <CardLayout>
      <div className="container mt-5">
        {/* {parseInt(getUserAuth()) === 1 ? <NumericStepper /> : ''} */}

        {/* <h3 className="title-md text-center mb-4">Please select your choice of investment method</h3> */}

        <h3 className="title-md text-center mb-2">Please select your desired investment amount</h3>
        <div  style={{ fontSize: 'small'}}>
          <p className="text-orange text-center text-wrap mb-5">
            This is only the commitment amount, this is not required to be invested upfront but gradually 
            over the next 5 years. You can also stop anytime you want.
          </p>
        </div>
        
        {/* hide the sip investment method till enach enabled SPNVC-199 */}

        {/* <div className="card mx-auto w-75 py-4 px-5  mb-4">
          <div className="d-flex justify-content-center align-items-center">
            <Radio
              sx={{
                color: "orange",
                "&.Mui-checked": {
                  color: "orange",
                },
              }}
              name="investment-type"
              value={2}
              checked={investmentType === 2}
              onChange={handleRadioChange}
            />
            <h5 className="text-orange ms-3 m-0">Monthly SIP Investment</h5>
          </div>
          {
            investmentType === 2 ?
              <div className="px-4">
                <p className="card-text text-center mt-3">Select Installment Amount</p>
                <CustomSlider
                  aria-label="custom thumb label"
                  step={5000}
                  min={50000}
                  max={1000000}
                  valueLabelDisplay="auto"
                  value={sipAmount.investmentAmount}
                  marks={[
                    { value: 50000, label: "INR 50,000" },
                    { value: 1000000, label: "INR 10,00,000" },
                  ]}
                  onChange={handleSipAmountChange}
                />
                <Divider
                  sx={{ bgcolor: "blue", width: "100%", margin: "20px 0" }}
                />
                <p className="card-text text-center mt-3">Select No of Installments</p>
                <CustomSlider
                  aria-label="custom thumb label"
                  step={1}
                  min={12}
                  max={60}
                  valueLabelDisplay="auto"
                  value={months}
                  marks={[
                    { value: 12, label: "12" },
                    { value: 60, label: "60" },
                  ]}
                  onChange={handleMonthChange}
                />
                <div className="d-flex justify-content-center">
                  <h5
                    className="text-green mt-3" style={{ borderBottom: "3px dashed" }}
                  >
                    Total INR:{" "}
                    {formattedCurrency(sipAmount.investmentAmount * months)}
                    /-
                  </h5>
                </div>
              </div> : ''
          }
        </div> */}

        <div className="card mx-auto w-75 py-4 px-5 mb-5 mt-5">
          <div className="d-flex justify-content-center align-items-center">
            {/* <Radio
              sx={{
                color: "orange",
                "&.Mui-checked": {
                  color: "orange",
                },
              }}
              className="text-orange"
              name="investment-type"
              value={1}
              checked={investmentType === 1}
              onChange={handleRadioChange}
            /> */}
            <h4 className="text-orange ms-3 m-0">Lumpsum Investment</h4>
          </div>
          {
          //  investmentType === 1 ?
              <div className="animated-div">
                <p className="card-text text-center mt-3">Select Amount</p>
                <CustomSlider
                  aria-label="custom thumb label"
                  step={500000}
                  min={2500000}
                  valueLabelDisplay="auto"
                  max={100000000}
                  value={lumpsumData.investmentAmount}
                  onChange={handleLumpsum}
                  marks={[
                    { value: 2500000, label: "INR 25 lacs" },
                    { value: 100000000, label: "INR 10 cr" },
                  ]}
                />
                <div className="d-flex justify-content-center">
                  <h5
                    className="text-green mt-3" style={{ borderBottom: "3px dashed" }}
                  >
                    Amount INR: {formattedCurrency(lumpsumData.investmentAmount)}/-
                  </h5>
                </div>
              </div> 
            // : ''
          }
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <button className="color-btn mx-auto" onClick={submit} disabled={isLoading}>
          {isLoading ? "Loading..." : "CONTINUE"}
        </button>
      </div>

      <CustomSnackbar
        open={openSnackbar}
        severity={toastStatus}
        duration={4000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </CardLayout>
  );
};

export default InvestmentMethod;
