import React from "react";
import { apigetFatcaList, apigetMcaList, apigetNominationList, getUserDetails } from "../apis/apis";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import CustomSnackbar from "../components/Toast/CustomSnackbar";
import CardLayout from "../components/layout/CardLayout";
import { Tooltip } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { formatDateTime, isForeigner } from "../../utils/StringUtil";
import { BASE_URL, SIGN_STATUS_SUCCESS, SIGN_STATUS_FAILED, SIGN_STATUS_PENDING } from "../../utils/constants/constants";
import { TableHeadButton } from "../../components/ui/TableHeadButton";
import { toLocalePrice } from "../../utils/StringUtil";
import { FLAG_REDIRECT_FROM_DOCUMENTS, FLAG_REDIRECT_FROM_PREVIEW, getUserAuth, setItem } from "../../utils/LocalStorageUtils";




function McaNominationDocuments({ investor }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState();
  const [nominationList, setNominationList] = useState([]);
  const [mcaList, setMcaList] = useState([]);
  const [fatcaList,setFatcaList]=useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [invDetails, setInvDetails] = useState({})

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const userDetails = () => {
    getUserDetails({ invRid: investor?.invRid })
      .then((res) => {
        setInvDetails(res.data);
      }).catch((error) => {
        setIsLoading(false);
        setErrorData(error);
        setOpenSnackbar(true);
      })
  }

  const getNominationDetails = () => {
    apigetNominationList({ invRid: investor?.invRid })
      .then((data) => {
        setIsLoading(false);
        setNominationList(data);
      }).catch((error) => {
        setIsLoading(false);
        setErrorData(error);
        setOpenSnackbar(true);
      });
  }

  const getMcaDetails = () => {
    apigetMcaList({ invRid: investor?.invRid })
      .then((data) => {
        setIsLoading(false);
        setMcaList(data.mcaList);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorData(error);
        setOpenSnackbar(true);
      })
  }

  const getFatcaDetails=()=>{
    apigetFatcaList({invRid:investor?.invRid})
    .then((data) => {
      setIsLoading(false);
      setFatcaList(data);
    })
    .catch((error) => {
      setIsLoading(false);
      setErrorData(error);
      setOpenSnackbar(true);
    })
  }


  useEffect(() => {
    userDetails();
    getNominationDetails();
    getMcaDetails();
    getFatcaDetails();
  }, []);



  return (
    <CardLayout goHome>
      <div className="container">
        <h3 className="fs-x-large-semibold mt-2 mt-md-5 mb-0">MCA</h3>

        <table className="table p-0 m-0 my-2">
          <tr className="fs-med-semibold text-muted">

            <th scope="col">
              <TableHeadButton
                label="Envelope Id"
                activeUp={false}
                activeDown={false}
              />
            </th>
            <th scope="col">
              <TableHeadButton
                label="Status"
                activeUp={false}
                activeDown={false}
                align="center"
                hideControls
              />
            </th>
            <th scope="col">
              <TableHeadButton
                label="Amount"
                activeUp={false}
                activeDown={false}
                leftAlign
                hideControls

              />
            </th>
            <th scope="col">
              <TableHeadButton
                label="Signed Date"
                activeUp={false}
                activeDown={false}
                align="center"
                hideControls

              />
            </th>


            {/* {
              invDetails?.invPref === 2 ?
                <th scope="col">
                  <TableHeadButton
                    label="Months"
                    activeUp={false}
                    activeDown={false}

                  />
                </th> : null
            } */}

            <th scope="col">
              <TableHeadButton
                label="MCA Files"
                activeUp={false}
                activeDown={false}
                align="center"
                hideControls
              />
            </th>

          </tr>
          {
            mcaList?.length ?
              mcaList?.map((data, key) => (
                <>
                  <tr
                    className="border-bottom pointer row-hover"
                    onClick={() => { }}
                    key={key}
                  >
                    <td className="text-start">
                      <div className="d-flex flex-column ps-1">
                        <p className="fs-6 p-0 m-0">{data.mcaEnvelopId ? data.mcaEnvelopId : 'N/A'}</p>
                      </div>
                    </td>
                    <td className="text-center">
                      <p className="p-0 m-0">
                        <span className={` fs-normal-medium px-2 py-1 transaction-status ${data.mcaStatus === SIGN_STATUS_SUCCESS ? "success" : data.mcaStatus === SIGN_STATUS_FAILED ? "failed" : data.mcaStatus === SIGN_STATUS_PENDING ? "pending" : ""
                          }`}>

                          {data.mcaStatus === SIGN_STATUS_SUCCESS && "Signed"}
                          {data.mcaStatus === SIGN_STATUS_FAILED && "In Review"}
                          {data.mcaStatus === SIGN_STATUS_PENDING && "Partially Signed"}
                        </span>
                      </p>
                    </td>

                    <td className="text-end p-0 m-0">
                      <div className="d-flex flex-column ps-1">
                        <p className="fs-6 p-0 m-0">{data.mcaAmount ? toLocalePrice(data.mcaAmount) : 'N/A'}</p>
                      </div>
                    </td>
                    {/* {invDetails.invPref === 2 ?
                    <td className="">
                      <div className="d-flex flex-column ps-1">
                        <p className="fs-6 p-0 m-0">{data.noOfInstallment}</p>
                      </div>
                    </td> : null
                  } */}

                    <td className="text-center">
                      <p className="p-0 m-0">
                        {data.invSignDatetime ?
                          formatDateTime(data.invSignDatetime, false) : "N/A"}
                      </p>
                    </td>
                    <td className="text-center">
                      <p className="p-0 m-0">
                        {
                          data.mcaFiles ? JSON.parse(data?.mcaFiles)?.map((fileData, fileIndex) => {
                            return (
                              <Tooltip title={fileData.actualFileName} key={fileIndex}>
                                <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                              </Tooltip>
                            );
                          }) : <p>N/A</p>}
                      </p>
                    </td>

                  </tr>
                </>
              )) :
              <tr >
                <td colspan="4"><p className="text-center">No Records Found</p>
                </td>
              </tr>
          }
        </table>
      </div>

      <div className="container">
        <h3 className="fs-x-large-semibold mt-4 mt-md-5 mb-0">Nomination</h3>
        {
         nominationList?.length ?
            <table className="table p-0 m-0 my-2">
              <tr className="fs-med-semibold text-muted">
                <th scope="col">
                  <TableHeadButton
                    label="Envelope Id"
                    activeUp={false}
                    activeDown={false}
                    hideControls
                  />
                </th>
                <th scope="col">
                  <TableHeadButton
                    label="Status"
                    activeUp={false}
                    activeDown={false}
                    hideControls
                  />
                </th>

                <th scope="col">
                  <TableHeadButton
                    label="Signed Date"
                    activeUp={false}
                    activeDown={false}
                    align="center"
                    hideControls

                  />
                </th>
                <th scope="col">
                  <TableHeadButton
                    label="Nomination Files"
                    activeUp={false}
                    activeDown={false}
                    align="center"
                    hideControls
                  />
                </th>

              </tr>

              {
                nominationList.map((data, key) => (
                  <>
                    <tr
                      className="border-bottom pointer row-hover"
                      onClick={() => { }}
                      key={key}
                    >

                      <td className="text-start">
                        <div className="d-flex flex-column ps-1">
                          <p className="fs-6 p-0 m-0">{data.envelopeId ? data.envelopeId : 'N/A'}</p>
                        </div>
                      </td>
                      <td className="">
                        <p className=" p-0 m-0">
                          <span className={` fs-normal-medium px-2 py-1 transaction-status ${data.signStatus === SIGN_STATUS_SUCCESS ? "success" : data.signStatus === SIGN_STATUS_FAILED ? "failed" : data.signStatus === SIGN_STATUS_PENDING ? "pending" : ""
                            }`}>

                            {data.signStatus === SIGN_STATUS_SUCCESS && "Signed"}
                            {data.signStatus === SIGN_STATUS_FAILED && "In Review"}
                            {data.signStatus === SIGN_STATUS_PENDING && "Partially Signed"}
                          </span>
                        </p>

                      </td>

                      <td className="text-center">
                        <p className="p-0 m-0">
                          {data.signDatetime ?
                            formatDateTime(data.signDatetime, false) : "N/A"}
                        </p>
                      </td>
                      <td className="text-center">
                        <p className="p-0 m-0">
                          {JSON.parse(data?.nominationFiles)?.length ?
                            JSON.parse(data?.nominationFiles)?.map((fileData, fileIndex) => {
                              return (
                                <Tooltip title={fileData.actualFileName} key={fileIndex}>
                                  <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                                </Tooltip>
                              );
                            }) : <p>N/A</p>}
                        </p>
                      </td>

                    </tr>
                  </>
                ))}
            </table> :
            <div className=''>
              <p className="text-center fs-large-medium mt-5">We need Nomination details to be included as part of onboarding. We have noticed your nomination details are missing. Kindly use the link below to share your nomination details with us.</p>
              <div className='d-flex  justify-content-center mt-3'>
                <button type="button"
                disabled={FLAG_REDIRECT_FROM_PREVIEW && !getUserAuth()}
                  onClick={() => 
                    {
                      setItem(FLAG_REDIRECT_FROM_DOCUMENTS,true)
                      history.push('/user/nomination')}
                    }
                  className="btn btn-primary px-3 py-2">Continue</button>
              </div>
            </div>

        }
      </div>

      {
      
      isForeigner()?
      <div className="container">
      <h3 className="fs-x-large-semibold mt-4 mt-md-5 mb-0">FATCA</h3>
      {
       fatcaList?.length ?
          <table className="table p-0 m-0 my-2">
            <tr className="fs-med-semibold text-muted">
              <th scope="col">
                <TableHeadButton
                  label="Envelope Id"
                  activeUp={false}
                  activeDown={false}
                />
              </th>
              <th scope="col">
                <TableHeadButton
                  label="Status"
                  activeUp={false}
                  activeDown={false}
                  hideControls
                />
              </th>

              <th scope="col">
                <TableHeadButton
                  label="Signed Date"
                  activeUp={false}
                  activeDown={false}
                  align="center"

                />
              </th>
              <th scope="col">
                <TableHeadButton
                  label="FATCA Files"
                  activeUp={false}
                  activeDown={false}
                  align="center"
                />
              </th>

            </tr>

            {
              fatcaList.map((data, key) => (
                <>
                  <tr
                    className="border-bottom pointer row-hover"
                    onClick={() => { }}
                    key={key}
                  >

                    <td className="text-start">
                      <div className="d-flex flex-column ps-1">
                        <p className="fs-6 p-0 m-0">{data.envelopeId ? data.envelopeId : 'N/A'}</p>
                      </div>
                    </td>
                    <td className="">
                      <p className=" p-0 m-0">
                        <span className={` fs-normal-medium px-2 py-1 transaction-status ${data.signStatus === SIGN_STATUS_SUCCESS ? "success" : data.signStatus === SIGN_STATUS_FAILED ? "failed" : data.signStatus === SIGN_STATUS_PENDING ? "pending" : ""
                          }`}>

                          {data.signStatus === SIGN_STATUS_SUCCESS && "Signed"}
                          {data.signStatus === SIGN_STATUS_FAILED && "In Review"}
                          {data.signStatus === SIGN_STATUS_PENDING && "Partially Signed"}
                        </span>
                      </p>

                    </td>

                    <td className="text-center">
                      <p className="p-0 m-0">
                        {data.signDatetime ?
                          formatDateTime(data.signDatetime, false) : "N/A"}
                      </p>
                    </td>
                    <td className="text-center">
                      <p className="p-0 m-0">
                        {JSON.parse(data?.fatcaFiles)?.length ?
                          JSON.parse(data?.fatcaFiles)?.map((fileData, fileIndex) => {
                            return (
                              <Tooltip title={fileData.actualFileName} key={fileIndex}>
                                <AttachFileIcon color="secondary" onClick={() => window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                              </Tooltip>
                            );
                          }) : <p>N/A</p>}
                      </p>
                    </td>

                  </tr>
                </>
              ))}
          </table> :
          <div className=''>
            <p className="text-center fs-large-medium mt-5">We need FATCA details to be included as part of onboarding. We have noticed your FATCA details are missing. Kindly use the link below to share your FATCA details with us.</p>
            <div className='d-flex  justify-content-center mt-3'>
              <button type="button"
              disabled={FLAG_REDIRECT_FROM_PREVIEW}
                onClick={() => 
                  {
                    setItem(FLAG_REDIRECT_FROM_DOCUMENTS,true)
                    history.push('/user/fatca')}
                  }
                className="btn btn-primary px-3 py-2">Continue</button>
            </div>
          </div>

      }
    </div>:null}
      
      <CustomSnackbar
        open={openSnackbar}
        severity="error"
        duration={4000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </CardLayout>
  );
}

export default McaNominationDocuments;
