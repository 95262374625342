import React from "react";
import "../../css/layout.css";

function LayoutKyc({ children }) {
  return (
    <div className="kyc-container">
      <div className="kyc-body">
        <img className="logo" src="/logo2.svg" alt="logo" />
        {/* <div className="child-component d-flex justify-content-center align-items-center"> */}
        {children}
        {/* </div> */}
      </div>
    </div>
  );
}

export default LayoutKyc;
