import React, { useEffect, useState } from "react";
import ModalLayout from './layout/ModalLayout';
import { apiCompanyUpdateDetials, apiTags } from "../apis/apis";
import { Chip, Divider, IconButton, Tooltip } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { BASE_URL } from "../../utils/constants/constants";

const CompanyUpdateModal = ({open, closeAction, companyUpdateRid}) => {
  const [companyUpdate, setCompanyUpdate] = useState({});
  const [tagsList, setTagsList] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    return `${day} ${month} ${year}`;
  }

  const getTags = (tags) => {
    apiTags({
      "tags": tags
    })
      .then((data) => {
        setTagsList(data.data);
      }).catch((e) => {
        console.log(e);
      });
  }

  const getCompanyUpdateDetails = (param = {}) => {
    param.cuRid = companyUpdateRid;
    apiCompanyUpdateDetials(param)
      .then((data) => {
        setCompanyUpdate(data.data);
        setAttachments(JSON.parse(data.data.attachments));
        data.data.tags !== null && getTags(data.data.tags);
      }).catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    if(open){
      setTagsList([]);
      setAttachments([]);
      getCompanyUpdateDetails();
    }
  }, [open]);

  // const downloadFile = async (response) => {
  //   const blob = new Blob([response.data], {
  //     type: response.headers.get("content-type"),
  //   });
  //   const downloadLink = document.createElement("a");
  //   const url = window.URL.createObjectURL(blob);
  //   downloadLink.href = url;
  //   downloadLink.setAttribute(
  //     "download",
  //     response.headers.get("content-disposition").split('"')[1]
  //   );
  //   downloadLink.click();
  //   window.URL.revokeObjectURL(url);
  // };

  // const getCompanyUpdateFile = () => {
  //   setActionLoader(true);
  //   apiFeeGenerateReport({
  //     oppRid: 0,
  //   })
  //     .then((data) => {
  //       console.log(data);
  //       setActionLoader(false);
  //       downloadFile(data);
  //       setAnchorEl(null);
  //     })
  //     .catch((error) => {
  //       setActionLoader(false);
  //       enqueueSnackbar(error, SNACKBAR_ERROR);
  //     });
  // };

  return (
    <ModalLayout
    open={open}
    title={companyUpdate.title}
    description={`${formatDate(companyUpdate.publishDate)}${companyUpdate.authorName ? ` • ${companyUpdate.authorName}` : ''}`}
    closeAction={closeAction}>
      <div dangerouslySetInnerHTML={{__html: companyUpdate.description}}></div>

      { tagsList.length > 0 &&
      <div className="my-3">
        <Divider />
        <h4 className="fs-med-medium text-muted my-3" style={{textTransform: "uppercase"}}>Tags</h4>
        <div className="d-flex gap-2 flex-wrap">
          {tagsList.map((data, key)=>{
            return(
              <Chip color="primary" label={data.ddValue} key={key} />
            );
          })}
        </div>
      </div> }

      { attachments.length > 0 &&
      <div className="my-3">
        <Divider />
        <h4 className="fs-med-medium text-muted my-3" style={{textTransform: "uppercase"}}>Attachments</h4>
        <div className="d-flex gap-2 flex-wrap">
          {attachments.map((data, key)=>{
            return(
              <Tooltip title={data.actualFileName} key={key}>
                <IconButton onClick={()=> window.open(`${BASE_URL}/${data.path}`, '_blank')}>
                  <AttachFileIcon color="secondary"/>
                </IconButton>
              </Tooltip>
            );
          })}
        </div>
      </div> }
    </ModalLayout>
  );
}

export default CompanyUpdateModal;